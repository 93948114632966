import React from 'react';
import {Dimensions, View} from 'react-native';

import {backgroundWelcome} from '../../assets/images';
import {Button} from '../../components/Button/Button';
import {ImgBackground} from '../../components/ImgBackground';
import {Logo} from '../../components/Logo';
import {SafeAreaView} from '../../components/SafeAreaView';
import {Text} from '../../components/Typography';
import {Messages} from '../../constants/Strings';
import {Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import styled from '../../lib/utils/styled';
import type {RootStackScreenProps} from '../../navigators/RootStackNavigator';
import {colors} from '../../styles';
import {requestFullscreen} from '../../util/Fullscreen';

const {WELCOME_SUBTITLE, WELCOME_TITLE} = Messages;
const {GET_STARTED} = Strings;
const {black} = colors;

interface ScreenProps extends RootStackScreenProps<typeof Screens.Welcome> {}

export const WelcomeScreen: React.FC<ScreenProps> = ({navigation}) => {
  const {height} = Dimensions.get('window');

  return (
    <ImgBackground
      imageStyle={{
        height: `${Math.max(100, (2.35 - 0.0017 * height) * 100)}%`,
      }}
      source={backgroundWelcome}>
      <Container>
        <TextContainer>
          <StyledLogo color={black} size={30} />

          <WelcomeSubtitle variant="s">{WELCOME_SUBTITLE}</WelcomeSubtitle>

          <WelcomeTitle variant="h1">{WELCOME_TITLE}</WelcomeTitle>
        </TextContainer>

        <ButtonContainer>
          <Button
            onPress={() => {
              requestFullscreen();
              navigation.navigate(Screens.LegalIntro);
            }}>
            {GET_STARTED}
          </Button>
        </ButtonContainer>
      </Container>
    </ImgBackground>
  );
};

const Container = styled(SafeAreaView)({
  flex: 1,
  alignItems: 'center',
});

const TextContainer = styled(View)({
  alignItems: 'center',
  flex: 1,
});

const WelcomeTitle = styled(Text)({
  textAlign: 'center',
  marginHorizontal: 16,
});

const WelcomeSubtitle = styled(Text)({
  fontFamily: 'Roboto-Medium',
  letterSpacing: 2,
  textAlign: 'center',
  paddingBottom: 5,
});

const ButtonContainer = styled(View)({
  width: '100%',
  padding: 24,
  marginTop: 24,
});

const StyledLogo = styled(Logo)({
  marginVertical: 40,
});
