import {Formik, FormikHelpers, FormikProps} from 'formik';
import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import * as Yup from 'yup';

import {backgroundForgotPassword} from '../../assets/images';
import {Alert} from '../../components/Alert';
import {
  StyledField,
  displayRightIconMessage,
  getRightIcon,
} from '../../components/StyledField';
import {Constants} from '../../constants';
import {ErrorMessages, Messages, Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {LockIcon} from '../../icons';
import {publicUserApi} from '../../lib/api/apis';
import {useWindowHeight} from '../../lib/hooks/useWindowHeight';
import styled from '../../lib/utils/styled';
import type {RootStackScreenProps} from '../../navigators/RootStackNavigator';
import {colors} from '../../styles';
import {ImageAndContentContainer, TermsAndPrivacy} from '../components';

const PasswordResetSchema = Yup.object().shape({
  password: Yup.string()
    .required(ErrorMessages.REQUIRED_PASSWORD)
    .min(8, ErrorMessages.PASSWORD_FORMAT_ERROR)
    .matches(
      Constants.VALID_PASSWORD_REGEX,
      ErrorMessages.PASSWORD_FORMAT_ERROR,
    ),

  confirmPassword: Yup.string()
    .required(ErrorMessages.REQUIRED_PASSWORD_CONFIRMATION)
    .oneOf([Yup.ref('password')], ErrorMessages.PASSWORDS_MATCH_ERROR),
});

interface PasswordResetValues {
  password: string;
  confirmPassword: string;
}

const {RESET_PASSWORD_BTN, RESET_PASSWORD_TITLE} = Messages;
const {NEW_PASSWORD, CONFIRM_PASSWORD} = Strings;

export interface Props {
  token: string;
}

interface ScreenProps
  extends RootStackScreenProps<typeof Screens.PasswordReset> {}

export const PasswordResetScreen: React.FC<ScreenProps> = ({
  route,
  navigation,
}) => {
  const ref =
    React.useRef<FormikProps<{password: string; confirmPassword: string}>>(
      null,
    );

  const {isMedium} = useWindowHeight();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleSubmit = async (
    values: PasswordResetValues,
    actions: FormikHelpers<PasswordResetValues>,
  ) => {
    actions.setSubmitting(true);

    try {
      const accessToken = route?.params?.token;

      const {email} = await publicUserApi
        .resetPassword(
          {id: 'me', resetPasswordDto: {password: values.password}},
          {headers: {Authorization: `Bearer ${accessToken}`}},
        )
        .then(r => r.data);

      navigation.navigate(Screens.PasswordConfirmed, {email});
    } catch (error: any) {
      if (error?.message === ErrorMessages.FORBIDDEN_RESOURCE) {
        Alert.alert(
          'Your password link has expired. Please submit a new reset password request.',
        );
      } else {
        Alert.alert(error?.message);
      }
    }

    actions.setSubmitting(false);
  };

  return (
    <ImageAndContentContainer
      isNotPath={Constants.IS_WEB}
      image={backgroundForgotPassword}
      header={{content: RESET_PASSWORD_TITLE}}
      primaryButton={{
        title: RESET_PASSWORD_BTN,
        onClick: () => {
          if (!ref.current?.isValid || ref.current.isSubmitting) return;
          ref.current?.submitForm();
        },
      }}
      bottom={{content: <TermsAndPrivacy />, style: {marginTop: 24}}}>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={PasswordResetSchema}
        innerRef={ref}>
        {({
          setFieldTouched,
          setFieldValue,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          errors,
        }) => (
          <FormContainer>
            <FieldsContainer>
              <StyledField
                icon={LockIcon}
                isBordered={true}
                height={isMedium ? 45 : 56}
                onChangeText={
                  !isSubmitting ? handleChange('password') : undefined
                }
                onBlur={
                  !isSubmitting
                    ? e => {
                        setFieldTouched('password');
                        handleBlur('password')(e);
                      }
                    : undefined
                }
                rightIconMessage={displayRightIconMessage(
                  values.password,
                  values.password.length >= 8 ? errors.password : undefined,
                  touched.password,
                )}
                rightIcon={getRightIcon(
                  values.password,
                  values.password.length >= 0 ? errors.password : undefined,
                  touched.password,
                )}
                rightIconColor={errors.password ? colors.red : colors.green}
                iconPress={() =>
                  values.password.length >= 8 &&
                  !!errors.password &&
                  setFieldValue('password', '')
                }
                textColor={colors.black50}
                leftIconColor={colors.black30}
                borderColor={colors.lightMediumGray}
                placeholder={NEW_PASSWORD}
                password={!showPassword}
                value={values.password}
              />
              <FieldFooter>
                <FieldFooterText>
                  {ErrorMessages.PASSWORD_MINIMUM_ERROR}
                </FieldFooterText>
                <TouchableOpacity
                  onPress={() => setShowPassword(prev => !prev)}>
                  <FieldFooterLink>
                    {showPassword ? 'Hide' : 'Show'} password
                  </FieldFooterLink>
                </TouchableOpacity>
              </FieldFooter>
              <StyledField
                icon={LockIcon}
                isBordered={true}
                height={isMedium ? 45 : 56}
                onChangeText={
                  !isSubmitting ? handleChange('confirmPassword') : undefined
                }
                onBlur={
                  !isSubmitting
                    ? e => {
                        setFieldTouched('confirmPassword');
                        handleBlur('confirmPassword')(e);
                      }
                    : undefined
                }
                rightIconMessage={displayRightIconMessage(
                  values.confirmPassword,
                  values.confirmPassword.length > 0
                    ? errors.confirmPassword
                    : undefined,
                  touched.confirmPassword,
                )}
                rightIconColor={
                  errors.confirmPassword ? colors.red : colors.green
                }
                rightIcon={getRightIcon(
                  values.confirmPassword,
                  values.confirmPassword.length > 0
                    ? errors.confirmPassword
                    : undefined,
                  touched.confirmPassword,
                )}
                iconPress={() =>
                  values.confirmPassword.length > 0 &&
                  errors.confirmPassword &&
                  setFieldValue('confirmPassword', '')
                }
                placeholder={CONFIRM_PASSWORD}
                textColor={colors.black50}
                leftIconColor={colors.black30}
                borderColor={colors.lightMediumGray}
                password={!showPassword}
                value={values.confirmPassword}
              />
            </FieldsContainer>
          </FormContainer>
        )}
      </Formik>
    </ImageAndContentContainer>
  );
};

const FormContainer = styled(View)({
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
});

const FieldsContainer = styled(View)({
  width: '100%',
});

const FieldFooter = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: 8,
  marginBottom: 10,
  paddingHorizontal: 11,
});

const FieldFooterText = styled(Text)({
  color: colors.gray67,
  fontFamily: 'Roboto-Regular',
  fontWeight: '400',
  fontSize: 12,
  letterSpacing: 0.1,
  lineHeight: 14.06,
});

const FieldFooterLink = styled(FieldFooterText)({
  textDecorationLine: 'underline',
});
