import React from 'react';

import {CustomAlert} from '../../components/CustomAlert';
import {useSpinner} from '../../contexts/spinner';
import {useAsyncFn} from '../../lib/hooks/useAsyncFn';
import {useShare} from '../../lib/hooks/useShare';
import {useTheme} from '../../lib/hooks/useTheme';
import {Share} from '../../lib/types';

type Callback = () => void | Promise<void>;

export interface Props {
  load: () => Promise<Share | undefined>;
  onShareCancel?: Callback;
  onShareEnd?: Callback;
  onShareStart?: Callback;
  icon: React.ComponentType<{onPress: () => void}>;
  alertTitle: string;
  alertMessage: string;
  loadingMessage: string;
}

export const ShareHandler = ({
  load,
  onShareCancel,
  onShareEnd,
  onShareStart,
  icon: Component,
  alertTitle,
  alertMessage,
  loadingMessage,
}: React.PropsWithChildren<Props>) => {
  const {share} = useShare();
  const theme = useTheme();
  const {alternateSpinnerColor} = theme;

  const [{loading}, trigger] = useAsyncFn(async () => {
    await Promise.resolve()
      .then(() => onShareStart?.())
      .then(load)
      .then(value => {
        if (!value) return;

        CustomAlert.alert(alertTitle, alertMessage, [
          {
            style: 'cancel',
            text: 'Cancel',
            onPress: () => {
              Promise.resolve().then(() => onShareCancel?.());
            },
          },
          {
            onPress: () => {
              share(value)
                .then(() => onShareEnd?.())
                .catch(() => onShareCancel?.());
            },
            text: 'Proceed',
          },
        ]);
      });
  }, []);

  useSpinner({
    isVisible: loading,
    ...(loading && {
      text: loadingMessage,
      color: alternateSpinnerColor,
    }),
  });

  return <Component onPress={trigger} />;
};
