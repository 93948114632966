import {useNavigation} from '@react-navigation/core';
import React from 'react';
import {useSelector} from 'react-redux';

import {Screens} from '../../constants/navigation';
import {userIdSelector} from '../../lib/redux/slices/userSlice';
import type {
  RootStackParamList,
  RootStackScreenProps,
} from '../../navigators/RootStackNavigator';
import {getNavigationRoute, routeToOptions} from '../../navigators/util';
import {colors} from '../../styles';
import {LoadingOverlay} from '../LoadingOverlay';

type Navigation = RootStackScreenProps<keyof RootStackParamList>['navigation'];

export const withUserGuard = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  const ComponentWithHOC: React.FC<P> = (props: P) => {
    const navigation = useNavigation<Navigation>();
    const loggedIn = !!useSelector(userIdSelector);

    React.useEffect(() => {
      if (loggedIn) return;

      const redirect = routeToOptions(getNavigationRoute(navigation));

      navigation.replace(Screens.Login, {redirect});
    }, [loggedIn]);

    if (!loggedIn)
      return <LoadingOverlay color={colors.white} backdrop allowInteractions />;

    return <Component {...props} />;
  };

  ComponentWithHOC.displayName = `withUserGuard(${Component.displayName ?? ''})`;

  return ComponentWithHOC;
};
