import {WebBluetoothConnectedDevice} from 'pikaparam';

import {Alert} from '../../../../components/Alert';
import {Constants} from '../../../../constants';
import {bleLog} from '../../../utils/Logger';
import {sleep} from '../../../utils/sleep';
import {OtaDevice} from '../OtaDevice';
import {ConnectionError} from '../types';
import {
  BleManagerBase,
  ConnectOptions,
  ConnectResult,
  IBleManager,
  OtaConnectOptions,
} from './BleManagerBase';
import {WebScanFilterBuilder} from './WebScanFilterBuilder';

const nav: Puffco.Navigator = window.navigator;

export class BleManager
  extends BleManagerBase<
    Puffco.PathBluetoothDevice,
    WebBluetoothConnectedDevice
  >
  implements IBleManager
{
  private scanner?: {stop: () => Promise<void>};

  public async connect({
    peripheral,
    ...o
  }: ConnectOptions): Promise<ConnectResult> {
    return this.initiateConnection({peripheral, ...o}, () => {
      if (!peripheral) {
        return this.scan(
          new WebScanFilterBuilder().includeAny().build(),
          // We will wait for 1 min because the user has to select a device
          60000,
        ).then(this.ensureDeviceFound.bind(this));
      }

      return this.scan(
        new WebScanFilterBuilder().includePeripheral(peripheral).build(),
        5000,
      ).then(this.ensureDeviceFound.bind(this));
    });
  }

  public async otaConnect({peripheral}: OtaConnectOptions): Promise<OtaDevice> {
    const onDisconnect = async () => void 0;

    const scanForAllOuis = (timeout: number) => {
      const filter = peripheral
        ? new WebScanFilterBuilder().includePeripheral(peripheral)
        : new WebScanFilterBuilder();

      return this.scan(filter.includeBootloader().build(), timeout);
    };

    const doesErrorIndicateNoDevice = (error: Error) =>
      [
        ConnectionError.USER_CANCELLED,
        ConnectionError.IOS_SCAN_TIMEOUT,
      ].includes(error.message as ConnectionError);

    const {ota} = await this.initiateConnection(
      {peripheral, onDisconnect},
      () => {
        if (!peripheral?.name)
          return scanForAllOuis(30000).then(this.ensureDeviceFound.bind(this));

        return this.scan(
          new WebScanFilterBuilder().includePeripheral(peripheral).build(),
          30000,
        )
          .catch(error => {
            if (doesErrorIndicateNoDevice(error)) return;
            throw error;
          })
          .then(peripheral => peripheral ?? scanForAllOuis(15000))
          .then(this.ensureDeviceFound.bind(this));
      },
    );

    return ota;
  }

  public async scanForAdvertisements(
    // eslint-disable-next-line
    callback: (device: any, event: any) => void,
  ): Promise<void> {
    // const peakFilter = [{services: [Uuids.loraxService]}];
    // await nav.bluetooth?.addEventListener('advertisementreceived', event => {
    //   const {device, manufacturerData} = event.data;
    //   const md: ArrayBuffer = manufacturerData.get(Constants.COMPANY_ID);
    //   if (!md) {
    //     return;
    //   }
    //   callback(device, Buffer.from(md));
    // });
    // await this.scanner?.stop();
    // this.scanner = await this.runAfterUserInteraction(() =>
    //   nav.bluetooth?.requestLEScan({filters: peakFilter}),
    // );
  }

  public async stopScanForAdvertisements(): Promise<void> {
    this.logger.info('stop advertisement scan');
    await this.scanner?.stop();
    this.scanner = undefined;
  }

  protected async createConnectedDevice(device: Puffco.PathBluetoothDevice) {
    return await WebBluetoothConnectedDevice.create(device, this.logger, {
      timeout: 10000,
    });
  }

  protected getConnectionMetadata({adData}: Puffco.PathBluetoothDevice) {
    return {rssi: adData?.rssi};
  }

  protected async bond(device: WebBluetoothConnectedDevice) {
    bleLog.info('Bonding.', {peripheralId: device.id});

    await device.triggerBonding(0).catch(error => {
      bleLog.error('Bonding failed.', {error});
      throw new Error(ConnectionError.IOS_BONDING_ERROR);
    });
  }

  // MTU is not supported on web
  protected async requestMtu() {}

  protected async stopScan() {
    await nav.bluetooth?.stopRequest?.();
  }

  private async scan(
    options: RequestDeviceOptions,
    timeout: number,
  ): Promise<BluetoothDevice | undefined> {
    this.logger.info('Scanning devices using filters.', {...options, timeout});

    const device = await this.runAfterUserInteraction(async () => {
      const timeoutId = setTimeout(async () => {
        await this.stopScan();
      }, timeout);

      return nav.bluetooth
        ?.requestDevice(options)
        .catch(error => {
          if (typeof error === 'string') throw new Error(error);
          throw error;
        })
        .finally(() => clearTimeout(timeoutId));
    });

    return device && this.getValidDevice(device) ? device : undefined;
  }

  private async ensureDeviceFound(device?: BluetoothDevice) {
    if (device) return device;

    throw new Error(ConnectionError.DEVICE_NOT_FOUND);
  }

  private getValidDevice(device: BluetoothDevice) {
    // Path Browser might return an invalid device when stopRequest has been called
    // so we will check for the existance of the device id.
    if (!device.id) return;

    return device;
  }

  private async runAfterUserInteraction<T>(callback: () => T) {
    if (Constants.IS_USING_PATH_BROWSER) return callback();

    if (nav.userActivation?.isActive) return callback();

    return new Promise<void>(resolve => {
      // Sleep is required, otherwise the alert is not displayed on web
      sleep(100).then(() => {
        Alert.alert(
          'Manual interaction required',
          'Click the button to continue',
          [{text: 'Continue', onPress: () => resolve()}],
          {onDismiss: () => resolve()},
        );
      });
    }).then(() => callback());
  }
}

export const bleManager: IBleManager = new BleManager();
