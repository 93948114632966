import React from 'react';
import {Helmet} from 'react-helmet';
import {Image, Linking, ScrollView, StyleSheet, Text, View} from 'react-native';

import {backgroundRewardEarned} from '../../assets/images';
import {CloseButton} from '../../components/CloseButton';
import {CopyBox} from '../../components/CopyBox';
import {LoadingOverlay} from '../../components/LoadingOverlay';
import {Modal} from '../../components/Modal';
import {Constants} from '../../constants';
import {Messages, Strings, interpolate} from '../../constants/Strings';
import {useReferralConfig} from '../../lib/hooks/useReferralConfig';
import {addOpacityToColorHex} from '../../lib/utils/colors';
import {colors} from '../../styles';
import {CtaButton} from './components';

const screenHeight = Constants.DIMENSIONS.HEIGHT;

const smallScreen = screenHeight < Constants.SCREEN_HEIGHT.PX_785;

interface Props {
  code: string;
}

const getBannerImageRatio = () =>
  1.2 / (screenHeight / Constants.SCREEN_HEIGHT.IPHONE_14_PRO_MAX);

export const NewRewardModal = ({code}: Props) => {
  const onClose = () => Modal.close();

  const {data: config, isLoading} = useReferralConfig();

  const amount = config?.amount;

  if (isLoading) return <LoadingOverlay />;

  return (
    <View style={styles.layout}>
      {Constants.IS_IOS_WEB && (
        <Helmet>
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content="black-translucent"
          />
        </Helmet>
      )}

      <CloseButton
        containerSize={30}
        size={20}
        style={styles.closeButton}
        color={colors.black}
        onPress={onClose}
      />

      <View style={styles.imageWrapper}>
        <Image
          resizeMode="cover"
          style={styles.image}
          source={backgroundRewardEarned}
        />
      </View>

      <View style={styles.container}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.contentContainer}>
          <Text style={styles.title}>
            {interpolate(Messages.REWARDS.YOU_EARNED_AMOUNT, {amount})}
          </Text>

          <Text style={styles.subtitle}>
            {interpolate(Messages.REWARDS.ENJOY_YOUR_REWARD, {amount})}
          </Text>

          <View style={styles.codeContainer}>
            <Text style={styles.label}>
              {interpolate(Messages.REWARDS.YOUR_DISCOUNT_CODE, {amount})}
            </Text>

            <CopyBox text={code} />
          </View>
        </ScrollView>

        <View style={styles.footer}>
          <View style={styles.ctaContainer}>
            <CtaButton
              title={Strings.START_SHOPPING}
              style={styles.ctaButton}
              textStyle={{
                color: colors.white,
                letterSpacing: 0.48,
                fontFamily: 'Roboto-Bold',
                fontSize: 16,
              }}
              onPress={() => {
                onClose();
                Linking.openURL(Constants.WEBSITE_URL);
              }}
            />
          </View>

          <Text style={styles.footerText}>
            {Messages.REWARDS.DISCOUNTS_CANNOT_STACK}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  layout: {
    width: '100%',
    height: '100%',
    minWidth: 200,
    backgroundColor: colors.white,
  },
  contentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 6,
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20,
    paddingHorizontal: 18,
  },
  imageWrapper: {
    width: '100%',
    aspectRatio: getBannerImageRatio(),
  },
  image: {
    width: '100%',
    height: '100%',
    maxHeight: 365,
  },
  title: {
    fontFamily: 'BigShouldersDisplay-Bold',
    fontSize: smallScreen ? 28 : 32,
    letterSpacing: 0.32,
    color: colors.black,
    textTransform: 'uppercase',
    marginBottom: '5%',
    paddingTop: '10%',
  },
  subtitle: {
    fontFamily: 'Roboto-Regular',
    color: colors.textOnSurfaceLight,
    lineHeight: 26,
    fontSize: smallScreen ? 14 : 16,
    marginBottom: '8%',
  },
  codeContainer: {
    width: '100%',
    gap: 6,
  },
  label: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    color: colors.black,
  },
  closeButton: {
    backgroundColor: colors.white,
    position: 'absolute',
    right: Constants.IS_IOS_WEB ? 28 : 20,
    top: Constants.IS_IOS_WEB ? (smallScreen ? 56 : 72) : 26,
    borderRadius: 50,
    zIndex: 10,
  },
  ctaContainer: {
    paddingHorizontal: 6,
  },
  ctaButton: {
    backgroundColor: colors.black,
    width: '100%',
  },
  footer: {
    width: '100%',
    marginTop: 12,
  },
  footerText: {
    fontSize: 12,
    marginTop: 16,
    textAlign: 'center',
    fontFamily: 'Roboto-Regular',
    color: addOpacityToColorHex(colors.black, 0.5),
  },
  loading: {
    width: '100%',
    backgroundColor: colors.white,
  },
});
