import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import {SectionList, SectionListRenderItemInfo} from 'react-native';
import {useSelector} from 'react-redux';

import {Alert} from '../../components/Alert';
import {HeatProfileCard} from '../../components/HeatProfileCard';
import {SafeAreaView} from '../../components/SafeAreaView';
import {SectionTitle} from '../../components/SectionTitle';
import {SwitchableBackground} from '../../components/SwitchableBackground';
import {Constants} from '../../constants';
import {Alerts, Strings, interpolate} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {useSpinner} from '../../contexts/spinner';
import {CheckIcon, ChevronLeftIcon} from '../../icons';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {useAppHeaderHeight} from '../../lib/hooks/useAppHeaderHeight';
import {useDisconnectGuard} from '../../lib/hooks/useDisconnectGuard';
import {useGetUpdatedPeakMoodLights} from '../../lib/hooks/useGetUpdatedPeakMoodLights';
import {useSafeArea} from '../../lib/hooks/useSafeArea';
import {useTheme} from '../../lib/hooks/useTheme';
import {useUpdateHeatProfileWithSuccessError} from '../../lib/hooks/useUpdateHeatProfileWithSuccessError';
import {connectedPeakSelector} from '../../lib/redux/slices/bleSlice';
import {
  getMoodLightSelector,
  setPeakMoodLights,
} from '../../lib/redux/slices/moodLightSlice';
import {
  activeProfilesSelector,
  archiveProfilesSelector,
  setActiveProfiles,
} from '../../lib/redux/slices/profilesSlice';
import {useAppDispatch} from '../../lib/redux/useAppDispatch';
import {
  MoodLight,
  Profile,
  ProfileT,
  isTHeatProfile,
  isTHeatProfileMoodLight,
} from '../../lib/types';
import {profilesMatch} from '../../lib/utils/profileFunctions';
import type {ControlCenterNavigatorScreenProps} from '../../navigators/HomeDrawerNavigator';
import {analytics} from '../../services/analytics';

interface Props
  extends ControlCenterNavigatorScreenProps<
    typeof Screens.AssignToAHeatProfile
  > {}

export const AssignToAHeatProfileScreen = ({navigation, route}: Props) => {
  useDisconnectGuard();
  const theme = useTheme();
  const {
    assignToAHeatProfileScreenTheme: {selectedBorderColor, background},
    navMenuIconColor,
    navMenuTitleStyle,
    alternateSpinnerColor,
  } = theme;
  const dispatch = useAppDispatch();

  const peak = useSelector(connectedPeakSelector);
  const actives = useSelector(activeProfilesSelector);
  const archives = useSelector(archiveProfilesSelector);
  const getMoodLight = useSelector(getMoodLightSelector); // Requires a minimum of firmware T
  const [selectedHeatProfile, setSelectedHeatProfile] =
    React.useState<Profile | null>(null);
  const [isProfileActive, setIsProfileActive] = React.useState<boolean | null>(
    null,
  );
  const {top} = useSafeArea();
  const HEADER_HEIGHT = useAppHeaderHeight();
  const getUpdatedPeakMoodLights = useGetUpdatedPeakMoodLights();

  const moodLight: MoodLight = route.params?.moodLight;
  const headerRight = route.params?.headerRight;
  const isPeakMoodLight = route.params?.isPeakMoodLight ?? false;
  const iconColor = route.params?.iconColor;
  const titleStyle = route.params?.titleStyle;

  const filteredArchives =
    actives &&
    archives &&
    archives.filter(
      archiveProfile =>
        actives.findIndex(activeProfile =>
          profilesMatch(activeProfile, archiveProfile),
        ) === Constants.INDEX_NOT_FOUND && !archiveProfile.deleted,
    );

  const onAssignSuccess = React.useCallback(
    (profile: ProfileT) => {
      analytics.trackEvent('mood light assign', {
        profile_id: profile.id,
        profile_name: profile.name,
        mood_light_id: moodLight.id,
        mood_light_type: moodLight.category,
      });

      Alert.alert(
        Alerts.MOOD_ASSIGNED,
        interpolate(Alerts.MOOD_ASSIGNED_BODY, {
          moodName: moodLight.name,
          profileName: profile.name,
        }),
      );
      navigation.goBack();
    },
    [moodLight.name, moodLight.id, moodLight.category],
  );

  const [{loading: isLoading}, apiFetch] = useUpdateHeatProfileWithSuccessError(
    {
      onSuccess: onAssignSuccess,
      onError: onAssignError,
    },
  );

  const assign = React.useCallback(
    (selectedHeatProfile: Profile) => {
      if (isProfileActive) {
        const activeIndex = actives.findIndex(
          active => active.id === selectedHeatProfile.id,
        );
        if (
          activeIndex !== Constants.INDEX_NOT_FOUND &&
          isTHeatProfile(selectedHeatProfile)
        ) {
          const updatedProfile: ProfileT = {
            ...selectedHeatProfile,
            isMoodLight: true,
            moodLightId: moodLight.id,
            modified: new Date().getTime(),
          };
          const newActives = cloneDeep(actives);
          newActives[activeIndex] = updatedProfile;
          const peakMoodLights = getUpdatedPeakMoodLights({
            actives: newActives,
          });
          dispatch(setPeakMoodLights(peakMoodLights));
          dispatch(setActiveProfiles(newActives));
          peak?.writeHeatProfiles(
            newActives,
            newActives.map(active =>
              isTHeatProfileMoodLight(active)
                ? peakMoodLights.find(
                    peakMoodLight => peakMoodLight.id === active.moodLightId,
                  )
                : undefined,
            ),
          );
          onAssignSuccess(updatedProfile);
        }
      } else {
        const archiveIndex = archives.findIndex(
          archive => archive.id === selectedHeatProfile.id,
        );
        if (archiveIndex !== Constants.INDEX_NOT_FOUND) {
          const selectedArchive = archives[archiveIndex];
          if (isTHeatProfile(selectedArchive)) {
            apiFetch({
              ...selectedArchive,
              isMoodLight: true,
              moodLightId: moodLight.id,
              modified: new Date().getTime(),
              // TODO: check user id
              userId: selectedArchive.userId!,
              // TODO: check user id
              moodLight: {...moodLight, userId: selectedArchive.userId!},
            });
          }
        }
      }
    },
    [
      actives,
      apiFetch,
      archives,
      getUpdatedPeakMoodLights,
      isProfileActive,
      moodLight,
      onAssignSuccess,
    ],
  );

  useSpinner({
    isVisible: isLoading,
    text: Strings.SAVING,
    color: alternateSpinnerColor,
  });

  useAdaptiveSafeArea();

  React.useEffect(() => {
    navigation.setOptions({
      headerTitle: 'ASSIGN TO A HEAT PROFILE',
      headerTitleStyle: titleStyle,
      headerLeft: () => (
        <ChevronLeftIcon
          onPress={() => navigation.goBack()}
          color={iconColor}
        />
      ),
      headerRight: () => headerRight,
      gestureEnabled: false,
    });
  }, [navigation, titleStyle, iconColor, headerRight]);

  React.useEffect(() => {
    navigation.setParams({
      titleStyle: navMenuTitleStyle,
      iconColor: navMenuIconColor,
      headerRight: selectedHeatProfile ? (
        <CheckIcon
          size={32}
          color={navMenuIconColor}
          onPress={() => !isLoading && assign(selectedHeatProfile)}
        />
      ) : undefined,
    });
  }, [
    navigation,
    assign,
    isLoading,
    navMenuIconColor,
    navMenuTitleStyle,
    selectedHeatProfile,
    top,
  ]);

  const renderItem = React.useCallback(
    ({
      item: profile,
      section,
    }: SectionListRenderItemInfo<Profile, {key: string; data: Profile[]}>) => (
      <HeatProfileCard
        moodLight={getMoodLight(
          (isTHeatProfileMoodLight(profile) && profile.moodLightId) || '',
        )}
        borderColor={selectedBorderColor}
        borderWidth={selectedHeatProfile?.id === profile.id ? 3 : 0}
        theme={theme}
        profile={profile}
        active={true}
        onPress={() => {
          if (!isLoading) {
            setSelectedHeatProfile(profile);
            setIsProfileActive(section.key === Strings.ACTIVE);
          }
        }}
      />
    ),
    [getMoodLight, isLoading, selectedHeatProfile, theme],
  );

  return (
    <SwitchableBackground
      background={background}
      style={{
        flex: 1,
      }}>
      <SafeAreaView
        style={{
          flex: 1,
          paddingTop: HEADER_HEIGHT,
          flexBasis: 0,
        }}>
        <SectionList
          sections={[
            {
              key: Strings.ACTIVE,
              data: cloneDeep(actives).sort(
                (a: Profile, b: Profile) => a.order - b.order,
              ),
            },
            ...(isPeakMoodLight
              ? []
              : [
                  {
                    key: `${Strings.ARCHIVE} ${archives.length}/${Constants.MAX_HEAT_PROFILES}`,
                    data: cloneDeep(filteredArchives).sort(
                      (a: Profile, b: Profile) => a.order - b.order,
                    ),
                  },
                ]),
          ]}
          keyExtractor={item => item.id + item.order}
          renderItem={renderItem}
          renderSectionHeader={item => (
            <SectionTitle
              title={item.section.key}
              style={{paddingHorizontal: 8}}
            />
          )}
          stickySectionHeadersEnabled={false}
        />
      </SafeAreaView>
    </SwitchableBackground>
  );
};

const onAssignError = (error: Error) => {
  Alert.alert(Alerts.MOOD_LIGHT_ASSIGN_ERROR_TITLE, error.message);
};
