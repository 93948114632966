import {useFocusEffect, useIsFocused} from '@react-navigation/native';
import React from 'react';
import {Dimensions, LayoutChangeEvent, TextInput, View} from 'react-native';
import {useDerivedValue} from 'react-native-reanimated';
import {useSelector} from 'react-redux';

import {AppText} from '../../components/AppText';
import {Button} from '../../components/Button';
import {Image} from '../../components/ImageWithFilter';
import {LoadingHalo} from '../../components/LoadingHalo';
import {PeakImageWithStaticChamber} from '../../components/PeakImage';
import {SafeAreaView} from '../../components/SafeAreaView';
import {StatusDisplay} from '../../components/StatusDisplay';
import {SwitchableBackground} from '../../components/SwitchableBackground';
import {Constants} from '../../constants';
import {ErrorMessages, Messages, Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {Connection} from '../../contexts/connection';
import {ConnectablePeripheral} from '../../lib/ble2/v2/BleManager/BleManagerBase';
import {ConnectionError} from '../../lib/ble2/v2/types';
import {useBackPress} from '../../lib/hooks/useBackPress';
import {useSafeArea} from '../../lib/hooks/useSafeArea';
import {isProductLimitedEdition, useTheme} from '../../lib/hooks/useTheme';
import {
  currentDeviceSelector,
  devicesSelector,
} from '../../lib/redux/slices/bleSlice';
import {bleWriteDeviceName} from '../../lib/redux/thunk';
import {useAppDispatch} from '../../lib/redux/useAppDispatch';
import {checkStringBytes} from '../../lib/utils/checkStringBytes';
import {addOpacityToColorHex} from '../../lib/utils/colors';
import {getIsPup} from '../../lib/utils/getIsPup';
import styled from '../../lib/utils/styled';
import type {MainNavigatorScreenProps} from '../../navigators/RootStackNavigator';
import {WithOptionalRedirect} from '../../navigators/params';
import {toDevicesList, toHome} from '../../navigators/util';
import {PermissionError} from '../../services/PermissionError';
import {locationService} from '../../services/location';
import {colors} from '../../styles';
import {
  defaultTheme,
  desertTheme,
  flourishTheme,
  guardianTheme,
  stormTheme,
} from '../../themes';
import {createFlowId} from '../../util/createFlowId';
import {
  activateScreenAwake,
  deactivateScreenAwake,
} from '../../util/screenAwake';
import {ConnectFooter, ConnectInfo, ConnectTitle} from './components';

const FOOTER_HEIGHT = 100;
const FOOTER_BOTTOM_MARGIN = 20;
const {width, height: windowHeight} = Dimensions.get('window');
const {
  PEAK_IMAGE_ORIGINAL_DIMENSIONS,
  PEAK_NAME_MAX_LENGTH,
  PEAK_NAME_PUP_MAX_LENGTH,
  IS_WEB,
} = Constants;
const {connectedGreen, errorColor, green, pureBlue, red, black, white} = colors;

export interface Props extends WithOptionalRedirect {
  deviceId?: string;
  deviceName?: string;
  redirectedFromLimitedEdition?: boolean;
}

type ScreenProps = MainNavigatorScreenProps<typeof Screens.Connect>;

export const ConnectScreen = ({route, navigation}: ScreenProps) => {
  const isFocused = useIsFocused();
  const themeFromDevice = useTheme();

  const isLimitedEdition = !!themeFromDevice.limitedEditionModalScreenTheme;
  const isGuardian = themeFromDevice === guardianTheme;
  const isDesert = themeFromDevice === desertTheme;
  const isFlourish = themeFromDevice === flourishTheme;
  const isStorm = themeFromDevice === stormTheme;

  const dispatch = useAppDispatch();

  const redirectedFromLimitedEdition =
    route.params?.redirectedFromLimitedEdition;
  const redirect = route.params?.redirect;

  const peripheralToReconnect = React.useMemo<
    ConnectablePeripheral | undefined
  >(() => {
    if (route.params?.deviceId)
      return {id: route.params.deviceId, name: route.params.deviceName};

    if (route.params?.deviceName)
      return {id: route.params.deviceId, name: route.params.deviceName};
  }, [route.params?.deviceId, route.params?.deviceName]);

  const device = useSelector(currentDeviceSelector);
  const savedDevices = useSelector(devicesSelector);
  const {peak} = Connection.useContainer();

  const connected = !!peak;
  const isPup = getIsPup(connected);

  const currentTheme = redirectedFromLimitedEdition
    ? themeFromDevice
    : defaultTheme;

  const [peakName, setPeakName] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState<string>();

  const [peakImageSize, setPeakImageSize] = React.useState({height: 0, y: 0});

  const {bottom} = useSafeArea();

  const [shouldNameDevice, setShouldNameDevice] = React.useState(false);

  const {progress, error, connect, preventReconnect, resetProgress} =
    Connection.useContainer();

  const flow = React.useRef({id: createFlowId(), attempt: 0});

  const connectToDevice = React.useCallback(async () => {
    setShouldNameDevice(false);

    const peak = await connect({
      peripheral: peripheralToReconnect,
      flowId: flow.current.id,
      attempt: ++flow.current.attempt,
    });

    if (peripheralToReconnect)
      return navigation.navigate(...(redirect ?? toDevicesList));

    setShouldNameDevice(true);

    if (peak.product.name && isProductLimitedEdition(peak.product.name))
      return navigation.navigate(Screens.LimitedEditionModal, {redirect});
  }, [shouldNameDevice, connect, peripheralToReconnect]);

  React.useEffect(() => {
    resetProgress();
    flow.current.id = createFlowId();
    flow.current.attempt = 0;
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      preventReconnect();
    }, [preventReconnect]),
  );

  React.useEffect(() => {
    if (redirectedFromLimitedEdition) return;

    activateScreenAwake();

    connectToDevice().catch(() => void 0);

    return () => {
      deactivateScreenAwake();
    };
  }, [redirectedFromLimitedEdition]);

  useBackPress(
    React.useCallback(() => {
      if (!device) return true;

      navigation.navigate(...(redirect ?? toDevicesList));

      return true;
    }, [redirect, device]),
  );

  const saveDeviceName = async (name: string) => {
    name = name.trim().toUpperCase();

    if (!name.length) return setErrorMsg(ErrorMessages.DEVICE_NAME_EMPTY);

    if (errorMsg) return;

    await dispatch(bleWriteDeviceName({name})).unwrap();

    navigation.replace(...(redirect ?? toHome));
  };

  const getTitle = (): string => {
    switch (error) {
      case ConnectionError.DEVICE_NOT_FOUND:
      case ConnectionError.IOS_SCAN_TIMEOUT:
      case ConnectionError.IOS_CONNECTION_NOT_FOUND:
        return ErrorMessages.DEVICE_NOT_FOUND_TITLE;
      case ConnectionError.CONNECTION_ERROR:
        return ErrorMessages.CONNECTION_ERROR_TITLE;
      case ConnectionError.USER_CANCELLED:
      case ConnectionError.WEB_USER_CANCELLED:
        return ErrorMessages.CONNECTION_CANCELED_ERROR_TITLE;
      case ConnectionError.PAIRING_ERROR:
        return ErrorMessages.PAIRING_ERROR_TITLE;
      case ConnectionError.IOS_DEVICE_FORGOTTEN:
        return ErrorMessages.IOS_FORGOTTEN_DEVICE_TITLE;
      case ConnectionError.IOS_BONDING_ERROR:
      case ConnectionError.ANDROID_BONDING_ERROR:
        return ErrorMessages.BONDING_ERROR_TITLE;
      case ConnectionError.IN_BOOTLOADER_STATE:
        return ErrorMessages.BOOTLOADER_TITLE;
      case PermissionError.LocationDisabled:
        return ErrorMessages.LOCATION_SERVICES_DISABLED_TITLE;
      case PermissionError.LocationCanceled:
      case PermissionError.LocationRequiresAction:
      case PermissionError.LocationDismissed:
        return ErrorMessages.LOCATION_ACCESS_DENIED_TITLE;
      case PermissionError.BluetoothDisabled:
        return ErrorMessages.BLUETOOTH_SERVICES_DISABLED_TITLE;
      case PermissionError.BluetoothDenied:
      case PermissionError.BluetoothCanceled:
      case PermissionError.BluetoothDismissed:
      case PermissionError.BluetoothRequiresAction:
        return ErrorMessages.BLUETOOTH_PERMISSION_DENIED_TITLE;
    }

    switch (progress.data) {
      case 'none':
      case 'starting':
      case 'scanning':
        return Messages.SEARCHING_FOR_DEVICE_TITLE;
      case 'retrieve_services':
      case 'bonding':
      case 'requesting_mtu':
      case 'connecting':
      case 'setting_up':
      case 'initializing':
        return Messages.CONNECTION_FOUND_TITLE;
      case 'reading':
      case 'done':
        return shouldNameDevice
          ? Messages.NAME_YOUR_PEAK_TITLE
          : Messages.CONNECTION_FOUND_TITLE;
    }
  };

  const getPeakColor = () => {
    if (error) return red;

    switch (progress.data) {
      case 'none':
      case 'scanning':
      case 'retrieve_services':
      case 'bonding':
      case 'requesting_mtu':
      case 'connecting':
      case 'setting_up':
      case 'initializing':
        return pureBlue;
      case 'reading':
      case 'done':
        if (isGuardian || isDesert || isFlourish || isStorm) return black;
        return green;
    }

    return white;
  };

  const getStatusBarStatus = (): string => {
    switch (progress.data) {
      case 'none':
      case 'starting':
      case 'scanning':
        return Strings.SEARCHING.toUpperCase();
      case 'retrieve_services':
      case 'bonding':
      case 'requesting_mtu':
        return Strings.INITIALIZING.toUpperCase();
      case 'connecting':
      case 'setting_up':
      case 'initializing':
        return Strings.PAIRING.toUpperCase();
      case 'reading':
      case 'done':
        return Strings.CONNECTED.toUpperCase();
    }
  };

  const getInstruction = (): string => {
    switch (error) {
      case ConnectionError.DEVICE_NOT_FOUND:
      case ConnectionError.IOS_SCAN_TIMEOUT:
      case ConnectionError.IOS_CONNECTION_NOT_FOUND:
        return ErrorMessages.DEVICE_NOT_FOUND_MSG;
      case ConnectionError.CONNECTION_ERROR:
        return ErrorMessages.CONNECTION_ERROR_MSG;
      case ConnectionError.USER_CANCELLED:
      case ConnectionError.WEB_USER_CANCELLED:
        return ErrorMessages.CONNECTION_CANCELED_ERROR_MSG;
      case ConnectionError.PAIRING_ERROR:
        return ErrorMessages.PAIRING_ERROR_MSG;
      case ConnectionError.IOS_DEVICE_FORGOTTEN:
        return ErrorMessages.IOS_FORGOTTEN_DEVICE_MSG;
      case ConnectionError.IOS_BONDING_ERROR:
      case ConnectionError.ANDROID_BONDING_ERROR:
        return ErrorMessages.BONDING_ERROR_MSG;
      case ConnectionError.IN_BOOTLOADER_STATE:
        return ErrorMessages.BOOTLOADER_MSG;
      case PermissionError.LocationDisabled:
        return ErrorMessages.LOCATION_SERVICES_DISABLED_MSG;
      case PermissionError.LocationCanceled:
      case PermissionError.LocationRequiresAction:
      case PermissionError.LocationDismissed:
        return locationService.required
          ? ErrorMessages.LOCATION_ACCESS_DENIED_12_MSG
          : ErrorMessages.LOCATION_ACCESS_DENIED_PRE_12_MSG;
      case PermissionError.BluetoothDisabled:
        return ErrorMessages.BLUETOOTH_SERVICES_DISABLED_MSG;
      case PermissionError.BluetoothDenied:
      case PermissionError.BluetoothCanceled:
      case PermissionError.BluetoothDismissed:
      case PermissionError.BluetoothRequiresAction:
        return IS_WEB
          ? ErrorMessages.BLUETOOTH_PERMISSION_DENIED_WEB_MSG
          : ErrorMessages.BLUETOOTH_PERMISSION_DENIED_MSG;
    }

    switch (progress.data) {
      case 'none':
      case 'starting':
      case 'scanning':
        return Messages.PAIRING_INSTRUCTIONS.pairing.body;
      case 'retrieve_services':
      case 'bonding':
      case 'requesting_mtu':
      case 'connecting':
      case 'setting_up':
      case 'initializing':
        return Messages.CONNECTION_FOUND_MSG;
      case 'reading':
      case 'done':
        return Messages.CONNECTION_SUCCESS_DONE_MSG;
    }
  };

  const haloColor = React.useMemo(() => {
    if (error) return '#FF3B30';

    switch (progress.data) {
      case 'reading':
      case 'done':
        return connectedGreen;
    }

    return currentTheme.primaryColor;
  }, [error, progress.data, currentTheme.primaryColor]);

  const haloPercentage = useDerivedValue(() => {
    if (error) return 1;
    return progress.value.value;
  }, [error, progress.value]);

  const makeGroundLayerStyle = () => {
    const {groundLayer} = currentTheme.connectScreenTheme;
    if (!groundLayer) {
      // use old logic if no groundLayer theme values are defined
      return {bottom: -(FOOTER_HEIGHT + FOOTER_BOTTOM_MARGIN + bottom)};
    }
    if (peakImageSize.height === 0) {
      // hide Ground Layer until Peak Image is rendered
      return {width: 0, height: 0};
    }

    const scaledPeakImageSizeWidth =
      peakImageSize.height *
      (PEAK_IMAGE_ORIGINAL_DIMENSIONS.width /
        PEAK_IMAGE_ORIGINAL_DIMENSIONS.height);
    const scaledGroundLayerImageWidth =
      scaledPeakImageSizeWidth *
      groundLayer.groundLayerImageWidthToPeakImageWidthRatio;
    const scaledGroundLayerImageHeight =
      scaledGroundLayerImageWidth *
      (groundLayer.groundLayerImageOriginalHeight /
        groundLayer.groundLayerImageOriginalWidth);
    const groundLayerImageTop =
      peakImageSize.height * groundLayer.normalizedPeakImageYOffset -
      scaledGroundLayerImageHeight * groundLayer.normalizedGroundLayerYOffset;

    return {
      top: groundLayerImageTop,
      width: scaledGroundLayerImageWidth,
      height: scaledGroundLayerImageHeight,
    };
  };

  const onGroundLayout = React.useCallback((e: LayoutChangeEvent) => {
    const {height, y} = e.nativeEvent.layout;
    setPeakImageSize({height, y});
  }, []);

  // scale bg image up to remove white gap for guardian theme
  const scaleBackgroundForGuardian = () => {
    return isGuardian && shouldNameDevice ? 1 + windowHeight * 0.0005 : 1;
  };

  return (
    <SwitchableBackground
      background={currentTheme.connectScreenTheme.background}
      imageStyle={{transform: [{scale: scaleBackgroundForGuardian()}]}}>
      <SafeAreaView style={{flex: 1}}>
        <HeaderContainer>
          <TopHeaderContainer>
            <ConnectTitle
              numberOfLines={2}
              style={{
                color: currentTheme.primaryColor,
                flex: 1,
                marginBottom:
                  windowHeight <= Constants.SCREEN_HEIGHT.GALAXY_S8 ? 5 : 10,
              }}>
              {getTitle()}
            </ConnectTitle>

            {/** TODO: The user might remain stuck here, but we don't know where to navigate them */}
            {!!error && savedDevices.length >= 1 && (
              <CancelButton
                onPress={() =>
                  navigation.navigate(...(redirect ?? toDevicesList))
                }>
                {Strings.CANCEL}
              </CancelButton>
            )}
          </TopHeaderContainer>

          <ConnectInfo style={{color: currentTheme.primaryColor, flex: 1}}>
            {getInstruction()}
          </ConnectInfo>

          {shouldNameDevice && isFocused && (
            <PeakNameContainer
              style={{
                backgroundColor: addOpacityToColorHex(
                  currentTheme.connectScreenTheme.peakNameBackgroundColor,
                  0.4,
                ),
              }}>
              <PeakNameInput
                style={{
                  color:
                    currentTheme.connectScreenTheme.peakNameTextColor ??
                    currentTheme.primaryColor,
                }}
                value={peakName}
                maxLength={
                  isPup ? PEAK_NAME_PUP_MAX_LENGTH : PEAK_NAME_MAX_LENGTH
                }
                editable
                // Workaround for this issue: https://github.com/facebook/react-native/issues/11068
                keyboardType="visible-password"
                onSubmitEditing={() => saveDeviceName(peakName)}
                returnKeyType="go"
                keyboardAppearance="dark"
                onLayout={() =>
                  device?.name && setPeakName(device.name.toUpperCase())
                }
                onChangeText={text => {
                  if (checkStringBytes(text.trim(), 'device', isPup)) {
                    setErrorMsg(undefined);
                    setPeakName(text.toUpperCase());
                  } else {
                    setErrorMsg(ErrorMessages.DEVICE_NAME_LONG);
                  }
                }}
                autoFocus
              />
              {!!errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
            </PeakNameContainer>
          )}
        </HeaderContainer>

        <BodyContainer onLayout={onGroundLayout}>
          {!!currentTheme.connectScreenTheme.groundLayerImage && (
            <GroundLayerContainer style={makeGroundLayerStyle()}>
              <Image
                source={currentTheme.connectScreenTheme.groundLayerImage}
                resizeMode="cover"
                style={{flex: 1, width: '100%'}}
              />
            </GroundLayerContainer>
          )}

          <PeakImageWithStaticChamber
            colorSource={getPeakColor()}
            style={{width: '100%', height: '80%'}}
            useDefaultTheme={currentTheme === defaultTheme}>
            {(!isLimitedEdition || !shouldNameDevice) && (
              <LoadingHalo
                color={haloColor}
                percentage={haloPercentage}
                style={{marginTop: -width * 0.2}}
              />
            )}
          </PeakImageWithStaticChamber>
        </BodyContainer>

        <ConnectFooter>
          <StatusDisplay
            status={
              error || shouldNameDevice ? undefined : getStatusBarStatus()
            }
            percentage={
              ['none', 'starting', 'scanning', 'reading', 'done'].includes(
                progress.data,
              )
                ? undefined
                : progress.value
            }
            error={error ? 'PLEASE TRY AGAIN' : ''}
            theme={currentTheme}
          />

          {shouldNameDevice ? (
            <Button
              onPress={() => saveDeviceName(peakName).catch(() => void 0)}
              theme={currentTheme.styledButtonTheme}>
              {Strings.NEXT}
            </Button>
          ) : (
            !!error && (
              <Button
                onPress={() => connectToDevice().catch(() => void 0)}
                theme={currentTheme.styledButtonTheme}>
                {error === PermissionError.BluetoothDisabled
                  ? Strings.ALLOW
                  : Strings.CONTINUE}
              </Button>
            )
          )}
        </ConnectFooter>
      </SafeAreaView>
    </SwitchableBackground>
  );
};

const HeaderContainer = styled(View)({
  height: 180,
  zIndex: 11,
});

const TopHeaderContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const CancelButton = styled(AppText)({
  color: colors.gray,
  fontSize: 14,
  textAlignVertical: 'center',
  marginRight: 16,
  marginTop: 15,
});

const PeakNameContainer = styled(View)({
  height: 62,
  width: '100%',
  alignItems: 'center',
  marginTop: 15,
  paddingHorizontal: 15,
});

const PeakNameInput = styled(TextInput)({
  width: '100%',
  height: '100%',
  textAlign: 'center',
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 20,
  letterSpacing: 0.18,
});

const ErrorMessage = styled(AppText)({
  color: errorColor,
  fontSize: 14,
  position: 'absolute',
  bottom: 0,
});

const BodyContainer = styled(View)({
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'flex-end',
  zIndex: 5,
});

const GroundLayerContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  position: 'absolute',
  width: '100%',
  top: '80%',
});
