import {Constants} from '../../../../constants';
import {IScanFilterBuilder, SearchablePeripheral} from './IScanFilterBuilder';
import {ScanFilterBuilderPikaparam} from './ScanFilterBuilderPikaparam';

// RequestDeviceOptions with filters
interface WebScanFilterBuilderResultPikaparam {
  filters: BluetoothLEScanFilter[];
  optionalServices?: BluetoothServiceUUID[] | undefined;
  optionalManufacturerData?: number[] | undefined;
}

export class WebScanFilterBuilderPikaparam<
  T extends WebScanFilterBuilderResultPikaparam,
> extends ScanFilterBuilderPikaparam<T> {
  // @ts-expect-error
  protected options: T = {filters: []};

  constructor() {
    super();
  }

  includeAny() {
    this.options.filters = [
      ...this.options.filters,
      ...this.appServiceIdentifiers.map(this.serviceToFilter.bind(this)),
      {
        manufacturerData: [
          {companyIdentifier: this.manufacturerData.companyIdentifier},
        ],
      },
    ];

    this.allowReadingServices();

    return this.includeBootloader();
  }

  includeBootloader() {
    this.options.filters = [
      ...this.options.filters,
      ...this.otaServiceIdentifiers.map(this.serviceToFilter.bind(this)),
      ...this.uniqueIdentifiers.map(this.ouiToFilter.bind(this)),
      {
        manufacturerData: [
          {
            companyIdentifier: this.manufacturerData.companyIdentifier,
            dataPrefix: new Uint8Array(
              this.manufacturerData.pikachuInBootloaderPrefix,
            ),
          },
        ],
      },
    ];

    this.allowReadingManufacturerData();

    return this;
  }

  protected allowReadingServices() {
    // This is needed on Web only
    this.options.optionalServices = [
      ...this.appServiceIdentifiers,
      ...this.otaServiceIdentifiers,
    ];
  }

  protected allowReadingManufacturerData() {
    // This is needed on Web only
    this.options.optionalManufacturerData = [
      ...(this.options.optionalManufacturerData ?? []),
      this.manufacturerData.companyIdentifier,
    ];
  }

  protected serviceToFilter(uuid: string) {
    return {services: [uuid]};
  }

  protected ouiToFilter(oui: string) {
    return {namePrefix: oui};
  }

  build(): T {
    return this.options;
  }
}

interface WebScanFilterBuilderResult
  extends WebScanFilterBuilderResultPikaparam {
  filters: Puffco.PathBluetoothLEScanFilter[];
}

export class WebScanFilterBuilder
  extends WebScanFilterBuilderPikaparam<WebScanFilterBuilderResult>
  implements IScanFilterBuilder<WebScanFilterBuilderResult>
{
  constructor() {
    super();
  }

  includePeripheral(peripheral: SearchablePeripheral): this {
    if (!Constants.IS_USING_PATH_BROWSER) {
      this.options.filters = [
        ...this.options.filters,
        ...(peripheral.name ? [{name: peripheral.name}] : []),
      ];

      return this;
    }

    // The {deviceId} filter should be the first item to trigger the non-interactive
    // reconnect process (non-standard). Omitting it will display the device selector.
    // Passing {name} should be the ideal behavior, but it looks after name change the
    // Pikachu unit is still available with its old name.
    // Needs validation: We don't use {deviceId} alone anymore because iOS seems to be
    // caching the device during the scan and thinks it found it, but we can never
    // connect to it because it's not there.
    this.options.filters = [
      ...this.options.filters,
      ...(peripheral?.name
        ? // TODO: after the next force update in Path Browser, remove deviceId: x
          // [{name: peripheral.name}, ...peripheral.id ? [{deviceId: peripheral.id }]}]
          [{deviceId: peripheral.id ?? 'x'}, {name: peripheral.name}]
        : []),
    ];

    return this;
  }
}
