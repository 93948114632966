import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import {ActivityIndicator, ActivityIndicatorProps, View} from 'react-native';

import {Text} from '../components/Typography';
import {useTheme} from '../lib/hooks/useTheme';
import styled from '../lib/utils/styled';

export type SpinnerProps = {
  isVisible?: boolean;
  text?: string;
  props?: ActivityIndicatorProps;
  color?: string;
  allowInteractions?: boolean;
};

interface SpinnerContextProps {
  display: (props: SpinnerProps) => void;
  close: () => void;
}

const SpinnerContext = createContext<SpinnerContextProps>({
  display: () => {},
  close: () => {},
});

export const SpinnerProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [spinnerProps, setSpinnerProps] = useState<SpinnerProps>();

  const {spinnerColor} = useTheme();

  return (
    <SpinnerContext.Provider
      value={{
        display: props => setSpinnerProps(props),
        close: () => setSpinnerProps(undefined),
      }}>
      {children}

      {spinnerProps?.isVisible && (
        <SpinnerContainer
          style={spinnerProps.allowInteractions && {pointerEvents: 'none'}}>
          <ActivityIndicator
            size="large"
            color={spinnerProps.color ?? spinnerColor}
            {...spinnerProps.props}
          />

          {spinnerProps.text && (
            <LoadingText
              variant="lead"
              style={{color: spinnerProps?.color ?? spinnerColor}}>
              {spinnerProps.text}
            </LoadingText>
          )}
        </SpinnerContainer>
      )}
    </SpinnerContext.Provider>
  );
};

export const useSpinner = (props: SpinnerProps) => {
  const spinner = useContext(SpinnerContext);

  React.useEffect(() => {
    spinner?.display(props);

    return () => spinner?.close();
  }, [props.isVisible]);
};

const SpinnerContainer = styled(View)({
  position: 'absolute',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 99999,
});

const LoadingText = styled(Text)({
  textTransform: 'uppercase',
  marginTop: 10,
});
