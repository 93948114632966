import React from 'react';
import {ActivityIndicator} from 'react-native';

import {Button, ButtonProps} from './Button';
import {buttonThemes} from './themes';

export interface Props extends ButtonProps {
  loading?: boolean;
}

export const LoadingButton: React.FC<Props> = ({
  loading,
  theme = 'black',
  disabled,
  leftIcon,
  rightIcon,
  children,
  ...rest
}) => {
  const {textColor} = buttonThemes[theme];

  return (
    <Button
      disabled={loading || disabled}
      leftIcon={!loading ? leftIcon : undefined}
      rightIcon={!loading ? rightIcon : undefined}
      {...{theme}}
      {...rest}>
      {loading ? <ActivityIndicator color={textColor} /> : children}
    </Button>
  );
};
