import React from 'react';
import {FlatList, Text, View} from 'react-native';

import {Button} from '../../../../../components/Button';
import {CloseButton} from '../../../../../components/CloseButton';
import {Title} from '../../../../../components/Title';
import {Constants} from '../../../../../constants';
import {Strings} from '../../../../../constants/Strings';
import {ChevronLeftIcon, ChevronRightIcon} from '../../../../../icons';
import {TypeStyledAsset} from '../../../../../lib/api/content-access/types';
import {responsive} from '../../../../../lib/utils';
import {addOpacityToColorHex} from '../../../../../lib/utils/colors';
import styled from '../../../../../lib/utils/styled';
import {colors} from '../../../../../styles';
import {AssetSlider} from './AssetSlider';

const narrowScreen = Constants.DIMENSIONS.WIDTH < 400;

interface GalleryViewerModalProps {
  onClose: () => void;
  assets: TypeStyledAsset[];
  index?: number;
  showTitle?: boolean;
  onItemChange?: (asset?: TypeStyledAsset) => void;
}

export const GalleryViewer: React.FC<GalleryViewerModalProps> = ({
  onClose,
  assets,
  onItemChange,
  index = 0,
  showTitle = false,
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(index);

  const sliderRef = React.useRef<FlatList>(null);

  React.useEffect(() => {
    const asset = assets[currentIndex];

    onItemChange?.(asset);
  }, [currentIndex]);

  const scrollToIndex = (index: number) => {
    if (!sliderRef.current) return;

    sliderRef.current.scrollToIndex({index});
  };

  const onNext = () => {
    if (currentIndex === assets.length - 1) return;

    scrollToIndex(currentIndex + 1);
    setCurrentIndex(currentIndex + 1);
  };

  const onPrevious = () => {
    if (currentIndex === 0) return;

    scrollToIndex(currentIndex - 1);
    setCurrentIndex(currentIndex - 1);
  };

  if (!assets[currentIndex]) return;

  return (
    <GalleryContainer>
      <GalleryHeader>
        <CountText>
          {currentIndex + 1} / {assets.length}
        </CountText>

        {showTitle && (
          <GalleryTitle numberOfLines={1}>
            {assets[currentIndex].fields.title}
          </GalleryTitle>
        )}

        <CloseButton color={colors.white} onPress={onClose} />
      </GalleryHeader>

      <AssetSlider
        ref={sliderRef}
        assets={assets}
        index={currentIndex}
        onIndexChange={setCurrentIndex}
        animated
      />

      <GalleryFooter>
        {currentIndex > 0 && (
          <GalleryButton
            theme="gray"
            leftIcon={ChevronLeftIcon}
            onPress={onPrevious}>
            {Strings.PREVIOUS}
          </GalleryButton>
        )}

        {currentIndex < assets.length - 1 && (
          <GalleryButton
            theme="white"
            rightIcon={ChevronRightIcon}
            onPress={onNext}>
            {Strings.NEXT}
          </GalleryButton>
        )}
      </GalleryFooter>
    </GalleryContainer>
  );
};

export const GalleryContainer = styled(View)({
  width: '100%',
  flex: 1,
  backgroundColor: colors.black,
  paddingTop: Constants.IS_NATIVE_ANDROID ? 24 : 64,
});

export const GalleryHeader = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 24,
  width: '100%',
  paddingHorizontal: 20,
});

export const GalleryFooter = styled(View)({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 12,
  marginTop: '10%',
  paddingHorizontal: responsive(28),
  marginBottom: 40,
});

export const CountText = styled(Text)({
  color: addOpacityToColorHex(colors.white, 0.5),
  fontSize: 16,
});

export const GalleryTitle = styled(Title)({
  fontSize: narrowScreen ? 14 : 16,
  textTransform: 'uppercase',
  fontFamily: 'Roboto-Bold',
});

export const GalleryButton = styled(Button)({
  flex: 1,
  width: 'auto',
});
