import {View} from 'react-native';

import styled from '../../../lib/utils/styled';

export const ConnectFooter = styled(View)({
  paddingHorizontal: '15%',
  marginBottom: '6%',
  width: '100%',
  zIndex: 6,
});
