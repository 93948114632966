import React from 'react';
import {View} from 'react-native';
import {useSelector} from 'react-redux';

import {Alert} from '../../components/Alert';
import {ImgBackground} from '../../components/ImgBackground';
import {SafeAreaView} from '../../components/SafeAreaView';
import {StyledButton} from '../../components/StyledButton';
import {Messages} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {
  connectedPeakSelector,
  currentDeviceIdSelector,
  removeBleDevice,
} from '../../lib/redux/slices/bleSlice';
import {useAppDispatch} from '../../lib/redux/useAppDispatch';
import styled from '../../lib/utils/styled';
import type {HomeEmulatedDrawerStackScreenProps} from '../../navigators/HomeDrawerNavigator';
import {BodyText, HeaderText} from './components';

export interface Props {}

type ScreenProps = HomeEmulatedDrawerStackScreenProps<
  typeof Screens.FactoryReset
>;

export const FactoryResetScreen = ({navigation}: ScreenProps) => {
  useAdaptiveSafeArea();
  const peak = useSelector(connectedPeakSelector);
  const currentDeviceId = useSelector(currentDeviceIdSelector);
  const dispatch = useAppDispatch();

  const resetAction = () => {
    if (!peak) {
      return Alert.alert(
        'Device Disconnected',
        Messages.FACTORY_RESET_DEVICE_DISCONNECTED,
      );
    }

    Alert.alert('Reset Device', Messages.FACTORY_RESET_CONFIRMATION, [
      {
        text: 'Cancel',
        style: 'cancel',
      },
      {
        text: 'OK',
        onPress: async () => {
          if (!peak) return;

          await peak.writeReadyModeOff();
          await peak.writeResetDevice();
          await peak.disconnect();

          if (currentDeviceId) dispatch(removeBleDevice(currentDeviceId));

          navigation.navigate(Screens.PairInstructions);

          Alert.alert(
            'Device Forgotten',
            "Please ensure the device is forgotten in your phone's bluetooth settings. Then click and hold the power button until the logo glows blue.",
          );
        },
      },
    ]);
  };

  return (
    <ImgBackground>
      <SafeAreaView style={{flex: 1, justifyContent: 'space-between'}}>
        <TextContainer>
          <HeaderText>
            This will restore the device to it&apos;s default factory settings,
            which includes:
          </HeaderText>
          <SectionText> Device Name </SectionText>
          <SectionText> Heat Profile Settings </SectionText>
          <SectionText> Ready Mode Setting </SectionText>
          <SectionText> Bluetooth Settings </SectionText>
        </TextContainer>
        <ButtonContainer>
          <StyledButton title={'Reset'} onPress={resetAction} />
        </ButtonContainer>
      </SafeAreaView>
    </ImgBackground>
  );
};

const TextContainer = styled(View)({
  display: 'flex',
  flex: 3,
  marginTop: 20,
  paddingTop: '15%',
  marginHorizontal: 25,
});

const ButtonContainer = styled(View)({
  marginBottom: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const SectionText = styled(BodyText)({
  marginVertical: 5,
  fontSize: 14,
});
