import React from 'react';
import {Pressable, PressableProps, StyleProp, ViewStyle} from 'react-native';

export interface Props extends PressableProps {
  style?: StyleProp<ViewStyle>;
}

export const PressableOpacity = ({
  style,
  ...rest
}: React.PropsWithChildren<Props>) => (
  <Pressable
    style={({pressed}) => [
      style,
      pressed && {
        opacity: 0.5,
      },
    ]}
    {...rest}
  />
);
