import React from 'react';
import {
  Text as RNText,
  TextProps as RNTextProps,
  TextStyle as RNTextStyle,
  StyleProp,
  StyleSheet,
} from 'react-native';

import {responsive as scale} from '../lib/utils';
import {colors} from '../styles';

export type Typography = 'h1' | 'h2' | 'h3' | 'lead' | 'body' | 's' | 'xs';

// Prevent passing fontSize and lineHeight in style
export interface TextStyle extends RNTextStyle {
  fontSize?: never;
  lineHeight?: never;
}

export interface TextProps extends RNTextProps {
  variant?: Typography;
  responsive?: boolean;
  style?: StyleProp<TextStyle>;
}

export const Text: React.FC<TextProps> = ({
  variant = 'body',
  style: styleProp,
  responsive,
  ...props
}) => {
  const variantStyle = styles[variant];

  const fontSize = variantStyle.fontSize ?? 16;
  const lineHeight = variantStyle.lineHeight ?? 26;

  const responsiveStyle: RNTextStyle = {
    fontSize: responsive ? scale(fontSize) : fontSize,
    lineHeight: responsive ? scale(lineHeight) : lineHeight,
  };

  const style: StyleProp<RNTextStyle> = [
    baseStyle,
    variantStyle,
    responsiveStyle,
    styleProp,
  ];

  return <RNText {...props} {...{style}} />;
};

const baseStyle: RNTextStyle = {
  color: colors.black,
};

const styles: Record<Typography, RNTextStyle> = StyleSheet.create({
  h1: {
    fontFamily: 'BigShouldersDisplay-Bold',
    textTransform: 'uppercase',
    fontSize: 50,
    // 2%
    letterSpacing: 0.02 * 50,
    lineHeight: 60,
  },
  h2: {
    fontFamily: 'BigShouldersDisplay-Bold',
    textTransform: 'uppercase',
    fontSize: 32,
    letterSpacing: 0.02 * 32,
    lineHeight: 42,
  },
  h3: {fontFamily: 'Roboto-Bold', fontSize: 24, lineHeight: 32},
  lead: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    lineHeight: 26,
    letterSpacing: 0.03 * 16,
  },
  body: {fontSize: 16, lineHeight: 26},
  s: {fontSize: 14, lineHeight: 22, color: colors.black50},
  xs: {fontSize: 12, lineHeight: 18, color: colors.black50},
});
