import React from 'react';
import {Image, Linking, StyleSheet, Text, View} from 'react-native';

import {backgroundMyRewards} from '../../assets/images';
import {noRewards} from '../../assets/images';
import {HeadingText} from '../../components/HeadingText';
import {Constants} from '../../constants';
import {Messages, Strings, interpolate} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {useSpinner} from '../../contexts/spinner';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {useReferralConfig} from '../../lib/hooks/useReferralConfig';
import {useReferralLink} from '../../lib/hooks/useReferralLink';
import {useReferrals} from '../../lib/hooks/useReferrals';
import {shareUrl} from '../../lib/utils/shareUrl';
import type {ReferralTabNavigatorScreenProps} from '../../navigators/ReferralProgramStack';
import {analytics} from '../../services/analytics';
import {colors} from '../../styles';
import {FixedFooter} from '../components';
import {
  CtaButton,
  ImageHeader,
  ReferralRewardCard,
  ScrollLayout,
  SupportSection,
} from './components';

export interface Props {}

interface ScreenProps
  extends ReferralTabNavigatorScreenProps<typeof Screens.MyRewards> {}

const EmptyState: React.FC<{amount?: string}> = ({amount}) => {
  const {data: url, isLoading} = useReferralLink();

  const onShare = async () => {
    if (!url) return;

    await shareUrl(
      {
        url,
        title: Strings.REFERRAL_PROGRAM.REFER_A_FRIEND,
        message: interpolate(Messages.REWARDS.USE_MY_LINK, {amount}),
      },
      {subject: Strings.REFERRAL_PROGRAM.REFER_A_FRIEND},
    )
      .catch(() => void 0)
      .then(() => {
        analytics.trackEvent('share', {content_type: 'referral'});
      });
  };

  return (
    <View style={emptyStyles.layout}>
      <View style={emptyStyles.container}>
        <Image
          resizeMode="contain"
          style={emptyStyles.image}
          source={noRewards}
        />

        <HeadingText style={emptyStyles.title}>
          {Messages.REWARDS.NO_REWARDS_EARNED}
        </HeadingText>

        <Text style={emptyStyles.subtitle}>
          {Messages.REWARDS.START_REFERRING}
        </Text>
      </View>

      <View style={emptyStyles.footer}>
        <CtaButton
          title={Strings.REFERRAL_PROGRAM.REFER_A_FRIEND}
          style={emptyStyles.ctaButton}
          onPress={onShare}
          disabled={isLoading}
        />
      </View>
    </View>
  );
};

export const MyRewardsScreen: React.FC<ScreenProps> = () => {
  useAdaptiveSafeArea();

  const {unused, used, isLoading: referralsLoading} = useReferrals();
  const {data: config, isLoading: configLoading} = useReferralConfig();

  const isLoading = referralsLoading || configLoading;

  const referrals = React.useMemo(() => unused.concat(used), [unused, used]);

  const amount = config?.amount;
  const cartMinimum = config?.cartMinimum;

  useSpinner({
    isVisible: isLoading,
    text: Strings.LOADING_DATA,
    color: colors.white,
  });

  if (isLoading) return null;

  if (!referrals.length) return <EmptyState {...{amount}} />;

  return (
    <View style={styles.screen}>
      <ScrollLayout style={styles.layout}>
        <ImageHeader source={backgroundMyRewards} style={styles.header} />

        <View style={styles.container}>
          <View>
            {referrals.map(referral => (
              <ReferralRewardCard key={referral.code} {...referral} />
            ))}
          </View>

          <SupportSection />

          <Text style={styles.footerText}>
            {interpolate(Messages.REWARDS.FOOTER_NOTE2, {amount, cartMinimum})}
          </Text>
        </View>
      </ScrollLayout>

      <FixedFooter>
        <CtaButton
          title={Strings.START_SHOPPING}
          style={styles.ctaButton}
          onPress={() => Linking.openURL(Constants.WEBSITE_URL)}
        />
      </FixedFooter>
    </View>
  );
};

const emptyStyles = StyleSheet.create({
  layout: {
    flex: 1,
    backgroundColor: colors.black,
    justifyContent: 'space-between',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: '8%',
  },
  title: {
    letterSpacing: 0.32,
    marginBottom: 20,
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: 'Roboto-Regular',
    color: colors.textOnSurfaceLight,
    lineHeight: 26,
    fontSize: 16,
    marginBottom: 16,
    textAlign: 'center',
  },
  ctaButton: {
    width: '100%',
  },
  image: {
    width: 190,
    height: 220,
    marginBottom: 42,
  },
  footer: {
    width: '100%',
    marginTop: 12,
    paddingHorizontal: 24,
    paddingTop: 12,
    paddingBottom: Constants.IS_NATIVE_ANDROID ? 24 : 48,
    backgroundColor: colors.black,
  },
});

const styles = StyleSheet.create({
  screen: {
    flex: 1,
  },
  layout: {
    flex: 1,
  },
  container: {
    flexGrow: 1,
    paddingHorizontal: 24,
    paddingBottom: 120,
    gap: 48,
  },
  ctaButton: {
    width: '100%',
  },
  header: {
    marginBottom: 20,
  },
  questionText: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    letterSpacing: 0.32,
  },
  contactSupportText: {
    color: colors.white,
    fontSize: 16,
    letterSpacing: 0.32,
    lineHeight: 22,
  },
  footerText: {
    fontSize: 14,
    opacity: 0.5,
    paddingBottom: Constants.IS_NATIVE_ANDROID
      ? 16
      : Constants.DIMENSIONS.HEIGHT * 0.14,
    fontFamily: 'Roboto-Regular',
    color: colors.white,
    lineHeight: 22,
  },
});
