import React from 'react';

import {LoadingOverlay} from '../../components/LoadingOverlay';
import {Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import type {RootStackScreenProps} from '../../navigators/RootStackNavigator';

interface ScreenProps extends RootStackScreenProps<typeof Screens.Loading> {}

export const LoadingScreen: React.FC<ScreenProps> = () => (
  <LoadingOverlay text={Strings.LOADING_DATA} />
);
