import {
  le05Peak,
  legacyPeakPeachBlackLightsBaseLeft,
  legacyPeakPeachBlackLightsBaseRight,
  legacyPeakPeachLightsGlassFarLeft,
  legacyPeakPeachLightsGlassFarRight,
  legacyPeakPeachLightsGlassMidLeft,
  legacyPeakPeachLightsGlassMidRight,
} from '../assets/images';
// Import from exact file to prevent cycle
// Import from exact file to prevent cycle
import {addOpacityToColorHex} from '../lib/utils/colors/addOpacityToColorHex';
import {colors} from '../styles';
import {defaultTheme} from './DefaultTheme';
import {
  ConnectScreenTheme,
  ControlCenterTheme,
  DabbingScreenTheme,
  HeatProfileCardTheme,
  HeatProfileEditScreenTheme,
  HeatProfileListScreenTheme,
  HeatProfileSelectScreenTheme,
  HomeScreenTheme,
  LimitedEditionModalScreenTheme,
  LimitedEditionVideoScreenTheme,
  NavMenuTitleStyle,
  PeakImageLayers,
  PeakImageTheme,
  PeakSection,
  TabBarTheme,
  Theme,
} from './Theme';

const BACKGROUND_COLOR = '#5F7665';
const LINEAR_GRADIENT_ARRAY = ['rgba(65, 87, 70, 0)', 'rgba(65, 87, 70, 1)'];

/* region: Components */
const navMenuTitleStyle: NavMenuTitleStyle = {
  ...defaultTheme.navMenuTitleStyle,
  color: colors.white,
};

const peakImageTheme: PeakImageTheme = {
  ...defaultTheme.peakImageTheme,
  peak: le05Peak,
  lightLayers: {
    glass: {
      farLeft: legacyPeakPeachLightsGlassFarLeft,
      farRight: legacyPeakPeachLightsGlassFarRight,
      midLeft: legacyPeakPeachLightsGlassMidLeft,
      midRight: legacyPeakPeachLightsGlassMidRight,
    },
    base: {
      left: legacyPeakPeachBlackLightsBaseLeft,
      right: legacyPeakPeachBlackLightsBaseRight,
    },
    ring: {},
  } as Record<PeakSection, PeakImageLayers>,
  peakDarkScreen: le05Peak,
};

const tabBarTheme: TabBarTheme = {
  ...defaultTheme.tabBarTheme,
  activeColor: colors.gold,
  inactiveColor: colors.goldMediumGray,
  backgroundColor: 'transparent',
};

const heatProfileCardTheme: HeatProfileCardTheme = {
  ...defaultTheme.heatProfileCardTheme,
  durationBubbleColor: colors.black,
  durationFontColor: colors.white,
  specularColorStart: colors.clear,
  specularColorEnd: colors.clear,
};
/* endregion: Components */

/* region: Screens */
const connectScreenTheme: ConnectScreenTheme = {
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
  peakNameBackgroundColor: colors.black,
};

const limitedEditionModalScreenTheme: LimitedEditionModalScreenTheme = {
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
};

const limitedEditionVideoScreenTheme: LimitedEditionVideoScreenTheme = {
  videoUrl:
    'https://puffco-prod.s3.us-west-2.amazonaws.com/welcome/flourish.mp4',
};

const heatProfileListScreenTheme: HeatProfileListScreenTheme = {
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
  hoverBackgroundColor: colors.gray,
};

const homeScreenTheme: HomeScreenTheme = {
  ...defaultTheme.homeScreenTheme,

  // General
  background: {
    color: BACKGROUND_COLOR,
    gradient: LINEAR_GRADIENT_ARRAY,
  },

  // Status info
  batteryBarBackgroundColor: colors.mediumGray50,
  batteryDisconnectedColor: addOpacityToColorHex(colors.white, 0.5),
  preserveIndicator: colors.black,
  statusTextStealthColor: colors.black,
  statusTitleTextColor: colors.white,
  stealthIconColor: colors.black,

  // Metric info
  dataBackgroundGradientArray: [
    {color: '#485F4DFF', location: 0.15},
    {color: '#38463300', location: 0.75},
  ],
  metricDisconnectedTextColor: addOpacityToColorHex(colors.white, 0.5),
  metricTextColor: colors.white,
  metricTitleTextColor: addOpacityToColorHex(colors.white, 0.5),
};

const dabbingScreenTheme: DabbingScreenTheme = {
  dabbingScreenActiveText: colors.white,
  dabbingScreenFadedText: colors.mediumGray50,
  dabbingButtonBackground: colors.bone,
  dabbingButtonBackgroundPressed: colors.black,
  dabbingButtonForeground: colors.black,
  dabbingButtonForegroundPressed: colors.bone,
  iconColor: colors.white,
  textColor: colors.white,
  fadedTextColor: colors.mediumGray50,
};

const heatProfileEditScreenTheme: HeatProfileEditScreenTheme = {
  ...defaultTheme.heatProfileEditScreenTheme,
  editTextColor: colors.white,
  iconColor: colors.white,
  navMenuTitleStyle: {
    ...defaultTheme.navMenuTitleStyle,
    color: colors.white,
  },
};

const heatProfileSelectScreenTheme: HeatProfileSelectScreenTheme = {
  primaryColor: colors.white,
  durationColor: colors.white50,
  startTextStyle: {color: colors.white, fontWeight: '400'},
  backgroundLineColor: colors.gray,
};

const controlCenterTheme: ControlCenterTheme = {
  titleText: colors.white,
  infoText: colors.white,
  fatSliderColor: colors.white,
  fatSliderBGColor: colors.black,
  buttonActiveForeground: colors.black,
  buttonInactiveForeground: colors.white,
  buttonActiveBackground: colors.white,
  buttonInactiveBackground: colors.black50,
  blurBackgroundColor: colors.transluscentLightGray,
  background: {
    color: colors.transluscentGray,
    gradient: LINEAR_GRADIENT_ARRAY,
  },
  boostScreenLabel: colors.baseText,
};
/* endregion: Screens */

export const flourishTheme: Theme = {
  ...defaultTheme,

  // Components
  primaryColor: colors.white,
  primaryTextColor: colors.white,
  logoTint: colors.invisible,
  navMenuTitleStyle,
  navMenuIconColor: colors.gold,
  peakImageTheme,
  heatProfileCardTheme,
  sectionDividerColor: colors.white20,
  sectionTitleTextColor: colors.white,
  statusDisplayTextColor: colors.white,
  spinnerColor: colors.black,
  tabBarTheme,
  styledButtonTheme: 'white',

  // Screens
  assignToAHeatProfileScreenTheme: {
    ...defaultTheme.assignToAHeatProfileScreenTheme,
    background: {color: BACKGROUND_COLOR, gradient: LINEAR_GRADIENT_ARRAY},
  },
  connectScreenTheme,
  limitedEditionModalScreenTheme,
  limitedEditionVideoScreenTheme,
  homeScreenTheme,
  heatProfileListScreenTheme,
  dabbingScreenTheme,
  heatProfileEditScreenTheme,
  heatProfileSelectScreenTheme,
  controlCenterTheme,

  dabTickTheme: {
    color: colors.white,
  },
};
