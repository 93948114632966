import {useIsFocused} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';

import {SpinnerProps, useSpinner} from '../contexts/spinner';
import styled from '../lib/utils/styled';
import {colors} from '../styles';

export type OverlayProps = Omit<SpinnerProps, 'isVisible'> & {
  backdrop?: boolean;
  backdropColor?: string;
};

export const LoadingOverlay: React.FC<OverlayProps> = ({
  backdrop,
  backdropColor,
  ...props
}) => {
  const isFocused = useIsFocused();

  useSpinner({
    isVisible: isFocused,
    ...props,
  });

  if (!backdrop) return null;

  return <Backdrop style={{backgroundColor: backdropColor}} />;
};

const Backdrop = styled(View)({
  flex: 1,
  backgroundColor: colors.black,
});
