import {Block, INLINES, Inline} from '@contentful/rich-text-types';
import React from 'react';
import {FlatList, Linking, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';

import {CloseButton} from '../../../../../components/CloseButton';
import {
  ArrowOrientation,
  ArrowPosition,
  GuideCard,
} from '../../../../../components/GuideCard';
import {HeadingText} from '../../../../../components/HeadingText';
import {PressableOpacity} from '../../../../../components/PressableOpacity';
import {
  RankIndicator,
  isPossibleRank,
} from '../../../../../components/RankIndicator';
import {Constants} from '../../../../../constants';
import {Strings} from '../../../../../constants/Strings';
import {ChevronLeftIcon, ChevronRightIcon} from '../../../../../icons';
import {
  TypeGallery,
  TypeGuide,
  TypeStyledAsset,
} from '../../../../../lib/api/content-access/types';
import {
  appFlagsSelector,
  updateAppFlags,
} from '../../../../../lib/redux/slices/appFlagsSlice';
import {useAppDispatch} from '../../../../../lib/redux/useAppDispatch';
import {analytics} from '../../../../../services/analytics';
import {colors} from '../../../../../styles';
import {RichTextRenderer} from '../../RichTextRenderer';
import {AssetSlider} from './AssetSlider';
import {
  CountText,
  GalleryButton,
  GalleryContainer,
  GalleryFooter,
  GalleryHeader,
  GalleryTitle,
} from './GalleryViewer';

const shortScreen =
  Constants.DIMENSIONS.HEIGHT < Constants.SCREEN_HEIGHT.IPHONE_X;

interface Props {
  onClose: () => void;
  elements: TypeGallery[];
  index?: number;
  showTitle?: boolean;
  guide?: TypeGuide;
  onItemChange?: (asset?: TypeGallery) => void;
}

export const ContestGalleryViewer: React.FC<Props> = ({
  onClose,
  elements,
  index = 0,
  showTitle,
  guide,
  onItemChange,
}) => {
  const sliderRef = React.useRef<FlatList>(null);

  const resetSlider = () => {
    if (!sliderRef.current) return;

    sliderRef.current.scrollToIndex({index: 0, animated: false});
  };

  const {hasSeenGuide} = useSelector(appFlagsSelector);
  const dispatch = useAppDispatch();

  const [currentIndex, setCurrentIndex] = React.useState(index);

  const [stepIndex, setStepIndex] = React.useState(
    hasSeenGuide.GALLERY ? undefined : 0,
  );

  React.useEffect(() => {
    if (!elements) return;

    const asset = elements[currentIndex];

    onItemChange?.(asset);
  }, [currentIndex]);

  if (!elements[currentIndex].fields.elements) return;

  const onNext = () => {
    if (currentIndex === elements.length - 1) return;

    resetSlider();
    setCurrentIndex(currentIndex + 1);
  };

  const onPrevious = () => {
    if (currentIndex === 0) return;

    resetSlider();
    setCurrentIndex(currentIndex - 1);
  };

  const getZIndex = (idx: number) =>
    idx === stepIndex ? {zIndex: 1} : {zIndex: 0};

  const guideSteps = hasSeenGuide.GALLERY ? undefined : guide?.fields.steps;

  const rank = currentIndex + 1;

  const displayName = elements[currentIndex].fields.displayName;

  const multipleLinks = (displayName?.content.length ?? 0) > 1;

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (
        node: Block | Inline,
        children: React.ReactNode,
      ) => {
        const {uri} = node.data;

        return (
          <PressableOpacity
            onPress={() => {
              Linking.openURL(uri);

              analytics.trackEvent('click', {
                content_type: 'social_link',
                link_url: uri,
              });
            }}>
            <HeadingText
              style={[
                styles.usernameText,
                multipleLinks && shortScreen && {fontSize: 22},
              ]}>
              {children}
            </HeadingText>
          </PressableOpacity>
        );
      },
    },
  };

  return (
    <GalleryContainer>
      <GalleryHeader>
        <CountText>
          {currentIndex + 1} / {elements.length}
        </CountText>

        {showTitle && (
          <GalleryTitle numberOfLines={1}>
            {elements[currentIndex].fields.title}
          </GalleryTitle>
        )}

        <CloseButton color={colors.white} onPress={onClose} />
      </GalleryHeader>

      <View style={[styles.sliderContainer, getZIndex(0)]}>
        <AssetSlider
          ref={sliderRef}
          assets={elements[currentIndex].fields.elements as TypeStyledAsset[]}
          withDots
          aspectRatio={shortScreen ? 1 : 0.8}
        />

        {guideSteps?.[0] && stepIndex === 0 && (
          <GuideCard
            step={guideSteps[0]}
            index={0}
            onPress={() => setStepIndex(stepIndex + 1)}
            style={[styles.guide, {bottom: -164}]}
          />
        )}
      </View>

      {displayName && (
        <View style={styles.usernameContainer}>
          <RichTextRenderer content={displayName} customOptions={options} />

          {guideSteps?.[2] && stepIndex === 2 && (
            <GuideCard
              step={guideSteps[2]}
              index={2}
              onPress={() => {
                setStepIndex(undefined);

                dispatch(
                  updateAppFlags({
                    hasSeenGuide: {...hasSeenGuide, GALLERY: true},
                  }),
                );
              }}
              arrowOrientation={ArrowOrientation.DOWN}
              arrowPosition={ArrowPosition.CENTER}
              style={styles.guide}
            />
          )}
        </View>
      )}

      {isPossibleRank(rank) ? (
        <RankIndicator {...{rank}} style={styles.rank} withIcon />
      ) : (
        <View style={[styles.rank, styles.rankPlaceholder]} />
      )}

      <GalleryFooter style={multipleLinks && {marginTop: '5%'}}>
        {currentIndex > 0 && (
          <GalleryButton
            theme="gray"
            leftIcon={ChevronLeftIcon}
            onPress={onPrevious}>
            {Strings.PREV_ENTRY}
          </GalleryButton>
        )}

        {currentIndex < elements.length - 1 && (
          <GalleryButton
            theme="white"
            rightIcon={ChevronRightIcon}
            onPress={onNext}>
            {Strings.NEXT_ENTRY}
          </GalleryButton>
        )}

        {guideSteps?.[1] && stepIndex === 1 && (
          <GuideCard
            step={guideSteps?.[1]}
            index={1}
            onPress={() => setStepIndex(stepIndex + 1)}
            style={[styles.guide, Constants.IS_WEB && {marginHorizontal: -28}]}
            arrowOrientation={ArrowOrientation.DOWN}
            arrowPosition={ArrowPosition.RIGHT}
          />
        )}
      </GalleryFooter>
    </GalleryContainer>
  );
};

const styles = StyleSheet.create({
  sliderContainer: {flex: 1, minHeight: 340},
  usernameContainer: {
    marginTop: shortScreen ? '5%' : '8%',
    alignItems: 'center',
  },
  usernameText: {
    fontSize: shortScreen ? 30 : 32,
  },
  rank: {
    marginTop: shortScreen ? '3%' : '6%',
  },
  rankPlaceholder: {
    height: 35,
  },
  guide: {
    width: Constants.DIMENSIONS.WIDTH,
    paddingHorizontal: Constants.PAGE_HORIZONTAL_PADDING,
  },
});
