import React from 'react';
import {View} from 'react-native';

import {Button} from '../../components/Button';
import {Video} from '../../components/Video';
import {Strings} from '../../constants/Strings';
import {Screens} from '../../constants/navigation';
import {useAdaptiveSafeArea} from '../../lib/hooks/useAdaptiveSafeArea';
import {useBackPress} from '../../lib/hooks/useBackPress';
import {useTheme} from '../../lib/hooks/useTheme';
import type {MainNavigatorScreenProps} from '../../navigators/RootStackNavigator';
import {WithOptionalRedirect} from '../../navigators/params';
import {colors} from '../../styles';
import {ConnectFooter} from './components';

const onBackPress = () => true;

export interface Props extends WithOptionalRedirect {}

type ScreenProps = MainNavigatorScreenProps<typeof Screens.LimitedEditionVideo>;

export const LimitedEditionVideoScreen = ({route, navigation}: ScreenProps) => {
  const {limitedEditionVideoScreenTheme, styledButtonTheme} = useTheme();
  const videoUrl = limitedEditionVideoScreenTheme?.videoUrl;

  const redirect = route.params?.redirect;

  useAdaptiveSafeArea();

  useBackPress(onBackPress);

  return (
    <View
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: colors.black,
      }}>
      {videoUrl && (
        <Video
          source={{uri: videoUrl}}
          shouldPlay={true}
          useNativeControls={false}
          // @ts-expect-error enum should be used instea
          resizeMode={'cover'}
          style={{height: '100%', width: '100%'}}
          isLooping
        />
      )}

      <ConnectFooter
        style={{
          position: 'absolute',
          bottom: '6%',
          marginBottom: 0,
        }}>
        <Button
          theme={styledButtonTheme}
          onPress={() => {
            navigation.navigate(Screens.Connect, {
              redirectedFromLimitedEdition: true,
              redirect,
            });
          }}>
          {Strings.NEXT}
        </Button>
      </ConnectFooter>
    </View>
  );
};
