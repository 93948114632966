import React from 'react';
import {Dimensions, StyleSheet, TouchableOpacityProps} from 'react-native';

import {Constants} from '../../constants';
import {IconProps} from '../../icons';
import {colors} from '../../styles';
import {ButtonTheme} from '../../themes';
import {PressableOpacity} from '../PressableOpacity';
import {Text} from '../Typography';
import {buttonThemes} from './themes';

const {height} = Dimensions.get('window');

const {PX_785} = Constants.SCREEN_HEIGHT;

const isMedium = height <= PX_785;

type ButtonPriority = 'primary' | 'secondary';

export type ButtonProps = TouchableOpacityProps & {
  theme?: ButtonTheme;
  leftIcon?: React.FC<IconProps>;
  rightIcon?: React.FC<IconProps>;
  priority?: ButtonPriority;
};

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  theme = 'black',
  priority = 'primary',
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  style,
  disabled,
  children,
  ...rest
}) => {
  const themeStyles = buttonThemes[theme];

  return (
    <PressableOpacity
      style={[
        styles.button,
        {
          backgroundColor: themeStyles.color,
          opacity: disabled
            ? Constants.INACTIVE_OPACITY
            : Constants.ACTIVE_OPACITY,
          borderColor:
            priority === 'primary'
              ? themeStyles.color
              : themeStyles.borderColor ?? colors.lightMediumGray,
        },
        disabled && styles.disabled,
        style,
      ]}
      {...{disabled}}
      {...rest}>
      {LeftIcon && (
        <LeftIcon
          strokeWidth={2}
          size={24}
          containerSize={24}
          color={themeStyles.textColor}
        />
      )}

      <Text
        variant="lead"
        style={[styles.text, {color: themeStyles.textColor}]}>
        {children}
      </Text>

      {RightIcon && (
        <RightIcon
          strokeWidth={2}
          size={24}
          containerSize={24}
          color={themeStyles.textColor}
        />
      )}
    </PressableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    width: '100%',
    maxHeight: 56,
    borderRadius: 80,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    paddingVertical: isMedium ? 8 : 14,
    borderWidth: 1,
  },
  text: {
    textTransform: 'uppercase',
    // line height leaves more empty space on bottom than on top, so it doesn't look centered
    ...(Constants.IS_WEB && {marginTop: 2}),
  },
  disabled: {
    opacity: Constants.INACTIVE_OPACITY,
  },
});
