import {
  heatProfileCoreCircleMask,
  le03BackgroundHomeScreen,
  le03BackgroundIntro,
  le03BackgroundNamePeak,
  le03BackgroundProfile,
  le03Gem,
  le03GroundLayer,
  le03HeatProfileCardBackground,
  le03HeatProfileCardBackgroundEdit,
  le03HeatProfileListBackground,
  le03HeatProfileListNavBackground,
  le03Peak,
  logoClose2,
  peakGuardianLightsBaseFarLeft,
  peakGuardianLightsBaseFarRight,
  peakGuardianLightsBaseMidLeft,
  peakGuardianLightsBaseMidRight,
  peakGuardianLightsBaseRingFarLeft,
  peakGuardianLightsBaseRingFarRight,
  peakGuardianLightsBaseRingMidLeft,
  peakGuardianLightsBaseRingMidRight,
  peakGuardianLightsGlassFarLeft,
  peakGuardianLightsGlassFarRight,
  peakGuardianLightsGlassMidLeft,
  peakGuardianLightsGlassMidRight,
  peakOpalDarkScreen,
} from '../assets/images';
// Import from exact file to prevent cycle
import {Constants} from '../constants';
import {addOpacityToColorHex} from '../lib/utils/colors/addOpacityToColorHex';
import {getTransparentGradientColors} from '../lib/utils/colors/getTransparentGradientColors';
import {shadeColor} from '../lib/utils/colors/shadeColor';
import {colors} from '../styles';
import {
  DarkStatusBarTheme,
  LightStatusBarTheme,
  defaultTheme,
} from './DefaultTheme';
// Import from exact file to prevent cycle
import {
  BottomSheetTheme,
  ConnectScreenTheme,
  ControlCenterTheme,
  DabbingScreenTheme,
  DevicesListScreenTheme,
  HeatProfileCardTheme,
  HeatProfileCoreImageTheme,
  HeatProfileListScreenTheme,
  HeatProfileScreenBackgroundTheme,
  HeatProfileSelectScreenTheme,
  HomeScreenTheme,
  LimitedEditionModalScreenTheme,
  LimitedEditionVideoScreenTheme,
  MoodLightCardTheme,
  MoodTypeButtonTheme,
  NavMenuTitleStyle,
  PeakImageLayers,
  PeakImageTheme,
  PeakSection,
  SaveToDeviceScreenTheme,
  StatusBarTheme,
  TabBarTheme,
  Theme,
} from './Theme';

const {IS_NATIVE_ANDROID} = Constants;

// General
const navMenuTitleStyle: NavMenuTitleStyle = {
  ...defaultTheme.navMenuTitleStyle,
  color: colors.black,
};

// Components
const bottomSheetTheme: BottomSheetTheme = {
  ...defaultTheme.bottomSheetTheme,
  backgroundColor: colors.opalWhite,
  buttonBackgroundColor: colors.white,
  buttonTextColor: colors.black,
  iconColor: colors.black,
  titleColor: colors.black,
  subtitleColor: colors.black50,
  dividerColor: colors.black10,
  lineColor: colors.black,
  gradientShadeFunction: (color: string) =>
    [-30, -55].map(shade => shadeColor(color, shade)),
};

const peakImageTheme: PeakImageTheme = {
  ...defaultTheme.peakImageTheme,
  peak: le03Peak,
  lightLayers: {
    glass: {
      farLeft: peakGuardianLightsGlassFarLeft,
      farRight: peakGuardianLightsGlassFarRight,
      midLeft: peakGuardianLightsGlassMidLeft,
      midRight: peakGuardianLightsGlassMidRight,
    },
    base: {
      farLeft: peakGuardianLightsBaseFarLeft,
      farRight: peakGuardianLightsBaseFarRight,
      midLeft: peakGuardianLightsBaseMidLeft,
      midRight: peakGuardianLightsBaseMidRight,
    },
    ring: {
      farLeft: peakGuardianLightsBaseRingFarLeft,
      farRight: peakGuardianLightsBaseRingFarRight,
      midLeft: peakGuardianLightsBaseRingMidLeft,
      midRight: peakGuardianLightsBaseRingMidRight,
    },
  } as Record<PeakSection, PeakImageLayers>,
  peakDarkScreen: peakOpalDarkScreen,
};

const moodLightCardTheme: MoodLightCardTheme = {
  ...defaultTheme.moodLightCardTheme,
  textColor: colors.white,
};

const moodTypeButtonTheme: MoodTypeButtonTheme = {
  ...defaultTheme.moodTypeButtonTheme,
  textColor: colors.black,
};

// Screens
const tabBarTheme: TabBarTheme = {
  ...defaultTheme.tabBarTheme,
  activeColor: colors.black,
  backgroundColor: colors.white,
  heatProfileListNavBg: le03HeatProfileListNavBackground,
};

const connectScreenTheme: ConnectScreenTheme = {
  background: {
    image: le03BackgroundNamePeak,
  },
  peakNameBackgroundColor: colors.darkGray,
  peakNameTextColor: colors.white,
  groundLayerImage: le03GroundLayer,
  groundLayer: {
    groundLayerImageOriginalHeight: 255,
    groundLayerImageOriginalWidth: 545,
    groundLayerImageWidthToPeakImageWidthRatio: 1.97,
    normalizedPeakImageYOffset: 0.952,
    normalizedGroundLayerYOffset: 0.29,
  },
};

const controlCenterTheme: ControlCenterTheme = {
  ...defaultTheme.controlCenterTheme,
  infoText: colors.black,
  fatSliderBGColor: colors.black50,
  blurBackgroundColor: colors.transluscentLightGray,
  background: {color: colors.lightestGrey},
  boostScreenLabel: colors.black,
};

const dabbingScreenTheme: DabbingScreenTheme = {
  ...defaultTheme.dabbingScreenTheme,
  dabbingScreenActiveText: colors.black,
  dabbingButtonBackground: colors.black50,
  dabbingButtonBackgroundPressed: colors.bone,
  dabbingButtonForeground: colors.bone,
  dabbingButtonForegroundPressed: colors.black,
};

const devicesListScreenTheme: DevicesListScreenTheme = {
  ...defaultTheme.devicesListScreenTheme,
  cardColor: colors.iOSGray,
  cardText: colors.black,
  listTitleText: colors.black,
  listDivider: '#979797',
  trashCanIconColor: colors.black,
  blurBackgroundColor: colors.transluscentLightGray,
};

const heatProfileListScreenTheme: HeatProfileListScreenTheme = {
  background: {
    image: le03HeatProfileListBackground,
  },
  hoverBackgroundColor: colors.gray,
};

const heatProfileSelectScreenTheme: HeatProfileSelectScreenTheme = {
  ...defaultTheme.heatProfileSelectScreenTheme,
  startTextStyle: {color: colors.black},
  backgroundLineColor: colors.lightGray,
  primaryColor: colors.black,
  durationColor: colors.black,
};

const heatProfileCoreImageTheme: HeatProfileCoreImageTheme = {
  baseImageSource: le03Gem,
  maskImageSource: heatProfileCoreCircleMask,
  tickColor: colors.white,
};

const heatProfileScreenBackgroundTheme: HeatProfileScreenBackgroundTheme = {
  ...defaultTheme.heatProfileScreenBackgroundTheme,
  gradientShadeFunction: getTransparentGradientColors,
  backgroundOpacityFactor: 'E6',
  background: le03BackgroundProfile,
  backgroundImageOriginalHeight: 2540,
  backgroundImageHeightToGemHeightRatioGlow: 5.15,
  backgroundImageHeightToGemHeightRatioAnimated: 4.85,
};
const homeScreenTheme: HomeScreenTheme = {
  ...defaultTheme.homeScreenTheme,

  // General
  background: {
    image: le03BackgroundHomeScreen,
  },
  groundLayerImage: le03GroundLayer,
  groundLayer: {
    groundLayerImageOriginalHeight: 255,
    groundLayerImageOriginalWidth: 545,
    groundLayerImageWidthToPeakImageWidthRatio: 1.97,
    normalizedPeakImageXOffset: 0.5,
    normalizedPeakImageYOffset: 0.952,
    normalizedGroundLayerXOffset: 0.5,
    normalizedGroundLayerYOffset: 0.29,
  },

  // Status info
  batteryBarBackgroundColor: colors.mediumGray79,
  batteryDisconnectedColor: addOpacityToColorHex(colors.black, 0.2),
  preserveIndicator: colors.black,
  statusTextStealthColor: colors.black,
  statusTitleTextColor: colors.black,
  stealthIconColor: colors.black,

  // Metric info
  dataBackgroundGradientArray: [
    {color: colors.white, location: 0},
    {color: addOpacityToColorHex(colors.white, 0), location: 1},
  ],
  metricDisconnectedTextColor: addOpacityToColorHex(colors.black, 0.2),
  metricTextColor: colors.black,
  metricTitleTextColor: colors.black50,

  // Limited edition info
  limitedEditionText: {
    // leftBorderColor: addOpacityToColorHex(appColors.black, 0.7),
    leftBorderGradientArray: [
      '#C4C4C4',
      '#393939',
      '#FFFFFF',
      '#DBDBDB',
      '#929292',
      '#4F4F4F',
      '#868686',
    ],
    name: {
      color: addOpacityToColorHex(colors.black, 0.8),
      value: 'Guardian',
    },
    numberString: {
      color: addOpacityToColorHex(colors.black, 0.8),
      value: '03',
    },
  },
};

const heatProfileCardTheme: HeatProfileCardTheme = {
  ...defaultTheme.heatProfileCardTheme,
  backgroundImage: le03HeatProfileCardBackground,
  editBackgroundImage: le03HeatProfileCardBackgroundEdit,
  primaryFontColor: colors.black,
  durationFontColor: colors.white,
  durationBubbleColor: colors.black,
  iconColor: colors.black,
  iosShadowAlpha: 75,
  // Use default values for iOS/Web
  specularColorStart: IS_NATIVE_ANDROID
    ? colors.invisible
    : defaultTheme.heatProfileCardTheme.specularColorStart,
  specularColorEnd: IS_NATIVE_ANDROID
    ? colors.invisible
    : defaultTheme.heatProfileCardTheme.specularColorEnd,
  gradientShadeFunction: (color: string) =>
    getTransparentGradientColors(color, 'FF'),
  overLayGradientColors: [
    addOpacityToColorHex(colors.white, 0.8),
    addOpacityToColorHex(colors.white, 0.5),
    addOpacityToColorHex(colors.white, 0),
  ],
};

const limitedEditionModalScreenTheme: LimitedEditionModalScreenTheme = {
  background: {image: le03BackgroundIntro},
  groundLayerImage: le03GroundLayer,
  groundLayer: {
    groundLayerImageOriginalHeight: 255,
    groundLayerImageOriginalWidth: 545,
    groundLayerImageWidthToPeakImageWidthRatio: 1.97,
    normalizedPeakImageYOffset: 0.952,
    normalizedGroundLayerYOffset: 0.29,
  },
};

const statusBarTheme: StatusBarTheme = {
  ...defaultTheme.statusBarTheme,
  themed: Constants.IS_NATIVE_ANDROID
    ? DarkStatusBarTheme
    : LightStatusBarTheme,
};

const limitedEditionVideoScreenTheme: LimitedEditionVideoScreenTheme = {
  // Video: Remove indiglow placeholder when official video is ready for production
  videoUrl:
    'https://puffco-prod.s3.us-west-2.amazonaws.com/welcome/guardian.mp4',
};

const saveToDeviceScreenTheme: SaveToDeviceScreenTheme = {
  background: heatProfileListScreenTheme.background,
  infoLabelColor: colors.black50,
};

export const guardianTheme: Theme = {
  ...defaultTheme,
  primaryColor: colors.black,
  primaryTextColor: colors.black,

  // General
  logoIcon: logoClose2,
  fadedTextColor: colors.black50,
  navMenuIconColor: colors.black,
  navMenuTitleStyle,
  spinnerColor: colors.black,

  // Components
  peakImageTheme,
  styledButtonTheme: 'black',
  heatProfileCardTheme,
  heatProfileCoreImageTheme,
  sectionTitleTextColor: colors.black,
  statusDisplayTextColor: colors.black,
  tabBarTheme,
  heatProfileScreenBackgroundTheme,
  moodTypeButtonTheme,
  moodLightCardTheme,
  bottomSheetTheme,
  statusBarTheme,

  // Screens
  assignToAHeatProfileScreenTheme: {
    ...defaultTheme.assignToAHeatProfileScreenTheme,
    background: {color: colors.lightestGrey},
  },
  homeScreenTheme,
  connectScreenTheme,
  controlCenterTheme,
  dabbingScreenTheme,
  devicesListScreenTheme,
  limitedEditionModalScreenTheme,
  limitedEditionVideoScreenTheme,
  heatProfileListScreenTheme,
  heatProfileSelectScreenTheme,
  saveToDeviceScreenTheme,
};
