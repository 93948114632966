import {Uuids} from 'pikaparam';

export abstract class ScanFilterBuilderPikaparam<T> {
  protected manufacturerData = {
    companyIdentifier: 0x0c03,
    // Version = 0x01
    // BLE Connectability State = 0x03
    // Reference https://puffco.atlassian.net/wiki/spaces/Firmware/pages/137986049/Manufacturer-Specific+Advertisement+Data
    pikachuInBootloaderPrefix: [0x01, 0x03],
  };

  protected appServiceIdentifiers = [Uuids.loraxService, Uuids.pikachuService];

  protected otaServiceIdentifiers = [
    Uuids.pupService,
    // Unfortunately, silabs will never broadcast the ota service
    Uuids.silabsOtaService,
  ];

  protected uniqueIdentifiers = [...Uuids.silabsOuis, ...Uuids.atmosicOuis];

  abstract includeAny(): this;
  abstract includeBootloader(): this;
  abstract build(): T;
}
