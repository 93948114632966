import React from 'react';

import {
  carouselAdvancedDab,
  carouselHeatProfile,
  carouselMoodLight,
} from '../../assets/images';
import {Button} from '../../components/Button';
import {Messages} from '../../constants/Strings';
import {Strings} from '../../constants/Strings';
import {Navigators, Screens} from '../../constants/navigation';
import {useBackPress} from '../../lib/hooks/useBackPress';
import type {RootStackScreenProps} from '../../navigators/RootStackNavigator';
import {RedirectionParameter} from '../../navigators/params';
import {ImageAndContentCarousel} from '../components';
import {ImageAndContentContainerProps} from '../components';

const {
  CAROUSEL_MOOD_LIGHT_TITLE,
  CAROUSEL_MOOD_LIGHT_BODY,
  CAROUSEL_HEAT_PROFILES_TITLE,
  CAROUSEL_HEAT_PROFILES_BODY,
  CAROUSEL_ADVANCED_DAB_TITLE,
  CAROUSEL_ADVACED_DAB_BODY,
  CREATE_ACCOUNT_BTN,
  CAROUSEL_DESCRIPTION,
} = Messages;

interface ScreenProps extends RootStackScreenProps<typeof Screens.Carousel> {}

export const CarouselScreen: React.FC<ScreenProps> = ({navigation}) => {
  const defaultValues: Partial<ImageAndContentContainerProps> = {
    subHeader: {content: CAROUSEL_DESCRIPTION},
  };

  const screens: ImageAndContentContainerProps[] = [
    {
      image: carouselMoodLight,
      header: {
        content: CAROUSEL_MOOD_LIGHT_TITLE,
      },
      body: {content: CAROUSEL_MOOD_LIGHT_BODY, style: {textAlign: 'left'}},
      ...defaultValues,
    },
    {
      image: carouselHeatProfile,
      header: {content: CAROUSEL_HEAT_PROFILES_TITLE},
      body: {content: CAROUSEL_HEAT_PROFILES_BODY, style: {textAlign: 'left'}},
      ...defaultValues,
    },
    {
      image: carouselAdvancedDab,
      header: {content: CAROUSEL_ADVANCED_DAB_TITLE},
      body: {content: CAROUSEL_ADVACED_DAB_BODY, style: {textAlign: 'left'}},
      ...defaultValues,
    },
  ];

  useBackPress(() => true);

  return (
    <ImageAndContentCarousel
      data={screens}
      loop={true}
      loopInterval={4000}
      primary={
        <Button
          onPress={() =>
            navigation.navigate(Screens.Register, {
              redirect: new RedirectionParameter(Navigators.MainNavigator, {
                screen: Screens.BluetoothStartPairing,
              }).encode(),
            })
          }>
          {CREATE_ACCOUNT_BTN}
        </Button>
      }
      secondary={
        <Button
          theme="white"
          priority="secondary"
          onPress={() =>
            navigation.navigate(Navigators.MainNavigator, {
              screen: Screens.BluetoothStartPairing,
            })
          }>
          {Strings.MAYBE_LATER}
        </Button>
      }
    />
  );
};
