import {MoodLightCategory} from 'puffco-api-axios-client';
import React from 'react';
import {Linking, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import {useAsync} from 'react-use';
import {ulid} from 'ulid';

import {Alert} from '../../components/Alert';
import {AppText} from '../../components/AppText';
import {CloseButton} from '../../components/CloseButton';
import {MultiGradientBackground} from '../../components/MultiGradientBackground';
import {PeakImageWithStaticChamber} from '../../components/PeakImage';
import {StyledButton} from '../../components/StyledButton';
import {Constants} from '../../constants';
import {
  Alerts,
  NonPathShareReminder,
  Strings,
  interpolate,
} from '../../constants/Strings';
import {Navigators, Screens} from '../../constants/navigation';
import {useSpinner} from '../../contexts/spinner';
import {shareApi} from '../../lib/api/apis';
import {useAppHeaderHeight} from '../../lib/hooks/useAppHeaderHeight';
import {useGetAndUpdateUsernameCache} from '../../lib/hooks/useGetAndUpdateUsernameCache';
import {useHasLed3Api} from '../../lib/hooks/useHasLed3Api';
import {useLantern} from '../../lib/hooks/useLantern';
import {useOpenApp} from '../../lib/hooks/useOpenApp';
import {useSafeArea} from '../../lib/hooks/useSafeArea';
import {useSaveMoodLight} from '../../lib/hooks/useSaveMoodLight';
import {useTheme} from '../../lib/hooks/useTheme';
import {userSelector} from '../../lib/redux/slices/userSlice';
import {CustomMoodLight, MoodType} from '../../lib/types';
import {isAdvancedMoodLight} from '../../lib/utils';
import styled from '../../lib/utils/styled';
import {verifyGuard} from '../../lib/utils/userFunctions';
import type {HomeEmulatedDrawerStackScreenProps} from '../../navigators/HomeDrawerNavigator';
import {toHome, toShareMoodlight} from '../../navigators/util';
import {analytics} from '../../services/analytics';
import {centeredColumnStyle, colors, fillStyle} from '../../styles';
import {defaultTheme} from '../../themes/DefaultTheme';

const {
  GOOGLE_PLAY_STORE_URL,
  IS_ANDROID_WEB,
  IS_USING_APP,
  IS_USING_DESKTOP_WEB,
  PATH_INSTRUCTIONS_URL,
} = Constants;

const placeholderMoodLight: CustomMoodLight = {
  category: MoodLightCategory.Custom,
  id: 'AAAAAAAAAAAAAAAAAAAAAAAAAA',
  modified: new Date().getTime(),
  colors: [colors.white],
  name: 'MOOD LIGHT',
  type: MoodType.NO_ANIMATION,
  tempo: 0,
  originalMoodLightId: 'AAAAAAAAAAAAAAAAAAAAAAAAAA',
  led3Meta: {
    userColors: [colors.white],
    tempoFrac: 0,
  },
};

export interface Props {
  shareId: string;
}

interface ScreenProps
  extends HomeEmulatedDrawerStackScreenProps<typeof Screens.SharedMood> {}

export const SharedMoodScreen: React.FC<ScreenProps> = ({
  navigation,
  route,
}) => {
  const {
    alternateSpinnerColor,
    sharedMoodScreenTheme: {gradientShadeFunction},
  } = useTheme();

  const user = useSelector(userSelector);
  const HEADER_HEIGHT = useAppHeaderHeight();
  const {bottom, top} = useSafeArea();

  const {shareId} = route.params ?? {};

  const {loading, value} = useAsync(async () => {
    if (!shareId) throw new Error('Share missing.');

    return await shareApi
      .getShareSnapshot({shareId})
      .then(r => r.data.moodLightSnapshot as CustomMoodLight | undefined);
  }, [shareId]);

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <CloseButton
          onPress={() =>
            navigation.canGoBack()
              ? navigation.goBack()
              : navigation.navigate(...toHome)
          }
        />
      ),
    });
  }, []);

  const sharedMoodLight = value ?? placeholderMoodLight;

  const usernameCache = useGetAndUpdateUsernameCache();
  const usernameToDisplay = usernameCache[sharedMoodLight.originalMoodLightId];

  const isAnimationCompatible = useHasLed3Api();

  const navigateHome = () => {
    navigation.navigate(Navigators.HomeTabNavigator, {
      screen: Screens.Home,
    });
  };

  const isIncompatiblePeak =
    !isAnimationCompatible &&
    IS_USING_APP &&
    isAdvancedMoodLight(sharedMoodLight);

  const [{loading: isSaving}, save] = useSaveMoodLight({
    onSuccess: React.useCallback((moodLight: CustomMoodLight) => {
      navigateHome();

      Alert.alert(
        Alerts.MOOD_DOWNLOADED,
        interpolate(Alerts.MOOD_SHARE_DOWNLOADED_BODY, {
          name: moodLight.name,
        }),
      );

      analytics.trackEvent('mood light download', {
        mood_light_id: moodLight.id,
      });
    }, []),
    onError: onSaveError,
  });

  useLantern(isIncompatiblePeak ? colors.defaultColor : sharedMoodLight);

  React.useEffect(() => {
    if (isIncompatiblePeak) {
      Alert.alert(
        Alerts.INCOMPATIBLE_ANIMATION_TITLE,
        Alerts.INCOMPATIBLE_ANIMATION_BODY,
        [
          {
            style: 'cancel',
            text: Strings.CLOSE,
          },
        ],
      );
    }
  }, [isIncompatiblePeak]);

  React.useEffect(() => {
    shareApi.updateShareMoodLightView({shareMoodLightId: shareId});
  }, [shareId]);

  const onSaveClick = () => {
    if (!user) {
      return Alert.alert(
        Alerts.ACCOUNT_NOT_LOGGED_IN,
        Alerts.FEATURE_ACCESS_MESSAGE,
        [
          {
            text: 'Sign In',
            onPress: () => {
              navigation.navigate(Screens.Login, {
                redirect: toShareMoodlight(shareId).encode(),
              });
            },
          },
          {text: 'Cancel', style: 'cancel'},
        ],
      );
    }

    verifyGuard(
      user,
      () => {
        if (!sharedMoodLight) return;

        save({
          ...sharedMoodLight,
          id: ulid(),
          modified: new Date().getTime(),
        });
      },
      navigation.navigate,
    );
  };

  useOpenApp(`/share/${shareId}`);

  useSpinner({
    isVisible: loading || isSaving,
    text: loading ? Strings.LOADING_DATA : Strings.SAVING,
    color: loading ? colors.white : alternateSpinnerColor,
  });

  if (loading) return null;

  return (
    <MultiGradientBackground
      colors={sharedMoodLight.colors}
      gradientShadeFunction={gradientShadeFunction}>
      <ScreenContainer
        style={{paddingTop: top + HEADER_HEIGHT, paddingBottom: bottom}}>
        <PeakContainer>
          <PeakImageWithStaticChamber
            colorSource={sharedMoodLight}
            shouldGlow={true}
            style={{height: '90%', width: '90%'}}
          />
        </PeakContainer>
        <MoodInfoContainer>
          <MoodTextContainer>
            <MoodName>{sharedMoodLight.name}</MoodName>
            {usernameToDisplay ? (
              <MoodUser>{`@${usernameToDisplay}`}</MoodUser>
            ) : null}
          </MoodTextContainer>
        </MoodInfoContainer>
        <ButtonContainer>
          <StyledButton
            disabled={
              IS_USING_DESKTOP_WEB || sharedMoodLight === placeholderMoodLight
            }
            {...(isIncompatiblePeak
              ? {
                  title: Strings.CLOSE.toUpperCase(),
                  onPress: navigateHome,
                }
              : IS_USING_APP
                ? {
                    title: Strings.SAVE.toUpperCase(),
                    onPress: () => onSaveClick(),
                  }
                : {
                    title: Strings.DOWNLOAD_APP,
                    onPress: () =>
                      Linking.openURL(
                        IS_ANDROID_WEB
                          ? GOOGLE_PLAY_STORE_URL
                          : PATH_INSTRUCTIONS_URL,
                      ),
                  })}
          />
          {!IS_USING_APP && (
            <TipText>
              {IS_USING_DESKTOP_WEB
                ? Strings.MOBILE_ONLY
                : NonPathShareReminder}
            </TipText>
          )}
        </ButtonContainer>
      </ScreenContainer>
    </MultiGradientBackground>
  );
};

const onSaveError = (error: Error) =>
  Alert.alert(Alerts.MOOD_LIGHT_CREATE_ERROR_TITLE, error.message);

const ScreenContainer = styled(View)({
  ...fillStyle,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
});

const PeakContainer = styled(View)({
  ...centeredColumnStyle,
  flex: 0.65,
  width: '100%',
});

const MoodInfoContainer = styled(View)({
  ...centeredColumnStyle,
  flex: 0.2,
  width: '100%',
});

const MoodTextContainer = styled(View)({
  ...centeredColumnStyle,
  width: '100%',
});

const MoodName = styled(Text)({
  color: colors.white,
  fontFamily: 'Roboto-Bold',
  fontWeight: '400',
  fontSize: 26,
  letterSpacing: 0.31,
  textTransform: 'uppercase',
  marginBottom: 8,
});

const MoodUser = styled(Text)({
  color: colors.white,
  fontFamily: 'Roboto-Regular',
  fontSize: 16,
  letterSpacing: 0.75,
  textTransform: 'uppercase',
  fontWeight: '400',
});

const ButtonContainer = styled(View)({
  ...centeredColumnStyle,
  flex: 0.15,
  width: '100%',
});

const TipText = styled(AppText)({
  fontSize: 14,
  marginVertical: 16,
  color: defaultTheme.dabbingScreenTheme.dabbingScreenFadedText,
});
