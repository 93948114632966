import {colors} from '../../styles';
import {ButtonTheme, StyledButtonTheme} from '../../themes';

export const buttonThemes: Record<ButtonTheme, StyledButtonTheme> = {
  black: {
    color: colors.black,
    textColor: colors.white,
  },
  white: {
    color: colors.white,
    textColor: colors.black,
  },
  gray: {
    color: colors.darkGreyMedium,
    textColor: colors.lightMediumGray,
  },
};
