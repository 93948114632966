import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import React from 'react';
import {StyleSheet, View} from 'react-native';

import {ErrorContainer} from '../components/ErrorContainer';
import {LoadingOverlay} from '../components/LoadingOverlay';
import {TabLabel} from '../components/TabLabel';
import {PageType} from '../lib/api';
import {useAdaptiveSafeArea} from '../lib/hooks/useAdaptiveSafeArea';
import {useLoadPages} from '../lib/hooks/useLoadPages';
import {useTabBarAnimation} from '../lib/hooks/useTabBarAnimation';
import {Page} from '../screens/components/contentful';
import {colors} from '../styles';

const CommunityTabNavigator = createMaterialTopTabNavigator();

export const CommunityTab = () => {
  const {pages, loading} = useLoadPages({type: PageType.COMMUNITY});

  const {animation, screenOptions} = useTabBarAnimation();
  const {onTouchStart, onTouchMove} = animation;

  useAdaptiveSafeArea();

  if (loading) return <LoadingOverlay color={colors.white} />;

  if (!pages) return <ErrorContainer />;

  return (
    <View {...{onTouchStart, onTouchMove}} style={styles.layout}>
      <CommunityTabNavigator.Navigator {...{screenOptions}}>
        {pages.map(page => (
          <CommunityTabNavigator.Screen
            key={page.sys.id}
            name={page.fields.screenName}
            component={Page}
            options={{
              tabBarLabel: ({focused}) => (
                <TabLabel {...{focused}} title={page.fields.title} />
              ),
            }}
          />
        ))}
      </CommunityTabNavigator.Navigator>
    </View>
  );
};

const styles = StyleSheet.create({
  layout: {
    flex: 1,
    backgroundColor: colors.black,
  },
});
